import React from 'react';

const AllSVGs = () =>(
  <svg style={{display:'none'}} xmlns='http://www.w3.org/2000/svg' version='1.1'>
    <defs id="defs">
      {/* hairstyle STYLE NEW */}
      <path id="hairstyle-1" d="M211.834 255.092C235.277 246.033 250.151 219.958 255.993 201.943C271.724 153.435 277.137 75.6447 273.648 -3.89429H86.0152C82.5262 75.6447 87.939 153.435 103.67 201.943C109.512 219.958 124.386 246.033 147.829 255.092C171.663 264.303 188 264.303 211.834 255.092Z"/>
      <path id="hairstyle-2" d="M211.834 255.092C235.277 246.033 250.151 219.958 255.993 201.943C269.767 159.47 275.63 104.88 274.534 39.8052C274.263 23.7502 263.064 18.4664 249.935 16.1057C205.498 8.11571 154.165 8.11574 109.728 16.1057C96.599 18.4664 85.3996 23.7502 85.1291 39.8052C84.0326 104.88 89.8961 159.47 103.67 201.943C109.512 219.958 124.386 246.033 147.829 255.092C171.663 264.303 188 264.303 211.834 255.092Z"/>
      <path id="hairstyle-3" d="M18.7003 161.317C25.4589 195.99 55.0744 200.504 100.228 200.504H251.998C279.59 200.504 334.386 157.426 339.1 108.044C341.466 83.2677 340.242 65.7478 325.337 45.8158C306.787 21.0109 282.843 11.1386 251.998 15.6164C220.659 20.1659 208.33 43.5842 176.741 45.8158C140.831 48.3528 119.079 26.2257 90.6309 40.2277C67.2785 51.7215 73.1775 73.3445 59.6002 96C44.1294 121.815 12.9423 131.777 18.7003 161.317Z"/>
      <path id="hairstyle-4" d="M210.786 255.092C234.229 246.033 254.945 201.943 254.945 201.943H259.991C315.219 201.943 359.991 157.172 359.991 101.943V-1.0119L0 -1.0119V101.943C0 157.172 44.7715 201.943 100 201.943H102.623C102.623 201.943 123.339 246.033 146.782 255.092C170.616 264.303 186.953 264.303 210.786 255.092Z"/>
      <path id="hairstyle-5" d="M276.796 95.3224C276.796 106.629 291.16 109.153 291.16 120.459C291.16 131.765 276.796 134.289 276.796 145.595C276.796 156.901 287.789 159.939 291.16 170.731C292.744 175.803 291.366 181.732 288.939 187.26C283.235 200.253 268.69 205.868 254.499 205.868L105.624 205.867C90.4088 205.867 74.9464 199.311 69.5392 185.089C67.7054 180.266 66.5898 175.229 67.1828 170.731C68.6606 159.522 81.547 156.901 81.547 145.595C81.547 134.289 67.1828 131.765 67.1828 120.459C67.1828 109.153 81.547 106.629 81.547 95.3225C81.547 84.0164 67.1828 81.4922 67.1828 70.1862C67.1829 58.8801 81.547 56.356 81.547 45.0499C81.547 33.7438 67.1829 31.2198 67.1829 19.9137C67.1829 8.60761 81.547 -5.22257 81.547 -5.22257L276.796 -5.22266C276.796 -5.22266 291.16 8.60756 291.16 19.9136C291.16 31.2197 276.796 33.7438 276.796 45.0498C276.796 56.3559 291.16 58.8801 291.16 70.1861C291.16 81.4922 276.796 84.0164 276.796 95.3224Z"/>
      <path id="hairstyle-6" d="M34 -2.53526e-05L109 205L249 205L324 0L179 -1.26763e-05L34 -2.53526e-05Z"/>
      <path id="hairstyle-7" d="M87.9105 156.202C84.6173 116.333 86.7482 56.389 156.238 45.8563L147.198 35.5043C147.198 35.5043 160.187 8.3369 179.069 8.03501C198.313 7.72734 212.327 35.5043 212.327 35.5043L202.529 46.256C265.406 57.8127 274.078 116.716 270.826 156.202C269.93 167.084 266.54 175.647 262.512 183.405H96.2259C90.7178 173.327 88.8093 167.084 87.9105 156.202Z"/>
      <path id="hairstyle-8" d="M90.8363 149.923C87.4794 96.5451 92.5251 51.2345 137.951 55.8142C131.615 40.4854 129.638 5.97284 179.523 5.97284C229.408 5.97284 229.409 41.5513 222.48 56.4321C266.912 52.1617 271.521 96.9834 268.209 149.923C267.39 158.068 265.667 162.731 262.839 170.38L262.666 170.847C247.777 211.136 179.523 242.847 179.523 242.847C179.523 242.847 111.271 211.135 96.3802 170.847L96.207 170.379C93.3794 162.731 91.6554 158.068 90.8363 149.923Z"/>
      <path id="hairstyle-9" fill-rule="evenodd" clip-rule="evenodd" d="M222.505 53.3863C265.973 66.6414 271.734 103.188 268.208 149.879C267.562 158.432 265.86 163.503 263.271 171.217C263.074 171.804 262.871 172.406 262.664 173.027C247.902 217.211 180.695 252.071 179.536 252.67C178.376 252.071 111.17 217.211 96.4084 173.027C96.2011 172.406 95.999 171.804 95.8019 171.217C93.2126 163.503 91.5103 158.432 90.8644 149.879C87.2975 102.645 93.2351 65.7931 138.099 52.9335C138.723 29.7715 149.887 1 180.296 1C210.904 1 222.015 30.1483 222.505 53.3863Z"/>
      <path id="hairstyle-10" d="M84 144.36C84 77.171 112.272 42 146.826 42C164.854 42 179.877 48.2088 179.877 48.2088C179.877 48.2088 194.9 41.9999 212.928 41.9999C247.482 41.9999 275.754 77.1711 275.754 144.36C275.754 207.453 225.327 244.5 179.877 244.5C134.427 244.5 84 207.453 84 144.36Z"/>
      <path id="hairstyle-11" d="M79.4717 118.042C92.3929 66.9661 115.998 41.9999 148.42 41.9999C165.334 41.9999 179.43 47.8255 179.43 47.8255C179.43 47.8255 193.526 41.9999 210.441 41.9999C242.862 41.9999 266.467 66.9661 279.389 118.042C296.34 185.047 222.074 162 179.43 162C136.786 162 62.5207 185.047 79.4717 118.042Z"/>
      <path id="hairstyle-12" d="M190.066 49.4606C193.115 47.8807 200.317 42.3195 192.3 37.751C175.003 27.8945 87 52.8803 87 139.458C87 226.036 130.971 225.595 181.146 226.958C232.641 228.357 272.373 221 272.373 139.458C272.373 108 262.674 81.0378 247.956 70.4088C247.956 70.4088 259.904 60.1092 251.589 52.8803C243.308 45.6802 228.276 49.4606 219.718 55.9234C225.261 51.7376 229.934 43.8647 223.875 41.2731C212.525 36.4185 194.145 47.4788 190.066 49.4606Z"/>
      <path id="hairstyle-13" d="M150.066 42.226C150.066 24.0968 175.01 19.5661 179.166 39.2041C183.322 19.5661 208.267 24.0968 208.267 42.226C217.967 27.1185 237.695 35.5 234.595 52.801C246.249 40.0393 264.077 54.1416 256.424 69.9034C272.366 63.7413 278.94 78.4846 273.396 105.678C265.093 146.403 269.13 217.353 232.309 237.62C195.488 257.887 162.845 257.887 126.023 237.62C89.202 217.353 93.2391 146.403 84.9366 105.678C79.3926 78.4846 85.9662 63.7413 101.909 69.9033C94.2555 54.1416 112.084 40.0393 123.737 52.801C120.637 35.5 140.366 27.1185 150.066 42.226Z"/>
      <path id="hairstyle-14" d="M96.9803 89.231C97.7365 80.7454 90.5125 80.6818 92.5797 67.0287C94.6469 53.3757 111.903 11 179.403 11C246.903 11 264.159 53.3757 266.226 67.0287C268.293 80.6818 261.069 80.7454 261.825 89.231C262.581 97.7166 269.704 106.062 270.423 114.124C271.141 122.185 265.621 131.824 266.362 140.144C267.104 148.465 270.828 156.853 271.584 165.338C272.34 173.824 271.258 182.153 266.786 191.527C254.553 217.168 207.583 230.098 179.403 232.609C151.222 230.098 104.252 217.168 92.0199 191.527C87.5476 182.153 86.4651 173.824 87.2213 165.338C87.9774 156.853 91.7018 148.465 92.4432 140.144C93.1847 131.824 87.6646 122.185 88.3829 114.124C89.1013 106.062 96.2241 97.7165 96.9803 89.231Z"/>
      <path id="hairstyle-15" d="M208.501 42.226C208.501 24.0968 183.557 19.5661 179.4 39.2041C175.244 19.5661 150.3 24.0968 150.3 42.226C140.6 27.1185 120.872 35.5 123.971 52.801C112.318 40.0393 94.4898 54.1416 102.143 69.9034C88.2005 61.7413 79.1709 68.5 78.1709 80.6783C77.1709 92.8567 79.4363 217.353 116.258 237.62C153.079 257.887 205.722 257.887 242.543 237.62C279.365 217.353 281.63 92.8567 280.63 80.6783C279.63 68.5 270.6 61.7413 256.658 69.9033C264.311 54.1416 246.483 40.0393 234.83 52.801C237.929 35.5 218.201 27.1185 208.501 42.226Z"/>
      <path id="hairstyle-16" d="M67.545 132.862C52.7888 124.771 57.9242 103.681 75.3785 104.352C60.3749 92.1351 72.3752 73.7817 87.1313 78.8722C78.2658 64.6319 92.2585 50.6016 106.525 58C97.9238 43.9202 115.749 30.7647 128.35 43.8444C124.025 28 143.235 18.8148 152.502 32.7627C152.502 15.9131 175.329 12.4632 179.3 30.6753C183.27 12.4632 206.098 15.9131 206.098 32.7628C215.364 18.8148 234.575 28 230.249 43.8444C242.85 30.7647 260.676 43.9202 252.075 58C266.341 50.6016 280.334 64.6319 271.468 78.8722C286.224 73.7817 298.224 92.135 283.221 104.352C300.675 103.681 305.811 124.771 291.054 132.862C306.782 136.951 304.005 156.508 288.76 161.291C304.189 165.452 298.651 186.661 283.221 185.5C293.876 198.493 288.221 212.924 257.548 212.616H179.3H101.052C70.3785 212.924 64.7239 198.493 75.3785 185.5C59.9488 186.661 54.41 165.452 69.8397 161.291C54.5943 156.508 51.8173 136.951 67.545 132.862Z"/>
      <path id="hairstyle-17" d="M54.1014 131C55.7121 112.067 75.6014 108 75.6014 108C75.6014 108 63.9912 88.3192 74.6014 72.5C85.2116 56.6808 106.358 61.3324 106.358 61.3324C106.358 61.3324 104.585 40.9918 120.101 33.2166C135.617 25.4415 151.597 35.2166 151.597 35.2166C151.597 35.2166 158.601 16.9999 179.742 16.9999C200.882 16.9999 207.886 35.2166 207.886 35.2166C207.886 35.2166 223.866 25.4415 239.382 33.2166C254.898 40.9918 253.126 61.3324 253.126 61.3324C253.126 61.3324 274.272 56.6808 284.882 72.5C295.493 88.3192 283.882 108 283.882 108C283.882 108 303.772 112.067 305.382 131C306.993 149.934 288.882 159 288.882 159C288.882 159 303.382 169 297.382 187C280.741 236.923 210.948 188.041 179.742 187.731C148.536 188.041 78.7424 236.923 62.1015 187C56.1015 169 70.6015 159 70.6015 159C70.6015 159 52.4907 149.933 54.1014 131Z"/>
      <path id="hairstyle-18" d="M132.124 55.1926C145.66 35.367 204.091 25.2492 238.651 49.867C273.212 74.4849 294.682 131.367 278.651 181.867C262.621 232.367 216.994 243.367 179.651 243.367C142.309 243.367 101.682 232.367 80.6513 181.867C59.6206 131.367 91.6512 55.1926 132.124 55.1926Z"/>
      <path id="hairstyle-19" d="M179.51 243C142.167 243 108.01 236.142 86.0098 193C64.0099 149.858 77.4582 81.8219 104.51 56.3199C131.561 30.8179 169.518 32.2734 179.51 39C189.502 32.2734 227.458 30.8179 254.51 56.3199C281.561 81.8219 295.01 149.858 273.01 193C251.01 236.142 216.853 243 179.51 243Z"/>
      <path id="hairstyle-20" d="M132.579 55.1926C146.114 35.367 204.545 25.2492 239.106 49.867C273.666 74.4849 285.136 131.367 269.106 181.867C253.075 232.367 217.448 243.367 180.106 243.367C142.763 243.367 112.136 232.367 91.1057 181.867C70.075 131.367 92.1057 55.1926 132.579 55.1926Z"/>
      <path id="hairstyle-21" d="M179.395 243C142.052 243 117.895 236.142 95.8948 193C73.895 149.858 81.3432 89.8219 108.395 60.3199C135.446 30.8178 169.403 34.2734 179.395 39C189.387 34.2734 223.343 30.8178 250.395 60.3199C277.446 89.8219 284.895 149.858 262.895 193C240.895 236.142 216.738 243 179.395 243Z"/>
      <path id="hairstyle-22" d="M131.842 55.1926C145.378 35.367 202.809 27.2492 233.37 51.867C263.93 76.4849 276.4 131.367 268.37 181.867C260.339 232.367 216.712 243.367 179.37 243.367C142.027 243.367 98.4002 232.367 90.3695 181.867C82.3389 131.367 94.3695 55.1926 131.842 55.1926Z"/>
      <path id="hairstyle-23" d="M179.269 245C133.365 245 98.8589 224.143 92.7848 195.002C86.7107 165.861 85.0655 101.828 110.427 65.3276C135.789 28.8271 169.153 36.2823 179.269 41.0087C189.385 36.2823 222.749 28.8271 248.11 65.3276C273.472 101.828 271.827 165.861 265.753 195.002C259.679 224.143 225.173 245 179.269 245Z"/>
      <path id="hairstyle-24" d="M179.687 33C88.604 33 77.2424 79.7847 90.6874 193C96.0389 238.064 145.85 253 179.687 253C213.524 253 263.336 238.064 268.687 193C282.132 79.7847 270.771 33 179.687 33Z"/>
      <path id="hairstyle-25" d="M179.506 33C102.423 33 78.0613 79.7847 91.5063 193C96.8579 238.064 145.669 253 179.506 253C213.343 253 262.155 238.064 267.506 193C280.951 79.7847 256.59 33 179.506 33Z"/>
      <path id="hairstyle-26" d="M179.439 33C104.356 33 78.9939 109.785 92.4389 193C95.4292 211.508 115.602 253 179.439 253C243.276 253 263.449 211.508 266.439 193C279.884 109.785 254.522 33 179.439 33Z"/>
      <path id="hairstyle-27" d="M179.12 33C125.036 33 84.6748 99.7847 93.1198 193C94.8114 211.672 125.283 253 179.12 253C232.957 253 263.428 211.672 265.12 193C273.565 99.7847 233.203 33 179.12 33Z"/>
      <path id="hairstyle-28" d="M291.858 178.658C296.588 161.025 282.858 156.658 278.976 144.658C275.095 132.658 286.858 126.158 270.858 109.658C254.858 93.1584 260.344 49.5612 210.429 38.5612C190.593 34.1897 179.419 40.3867 179.419 40.3867C179.419 40.3867 168.245 34.1897 148.408 38.5611C98.4936 49.5611 103.98 93.1584 87.9798 109.658C71.9797 126.158 83.7426 132.658 79.8612 144.658C75.9798 156.658 62.2492 161.025 66.9798 178.658C71.7104 196.292 86.9196 200.658 179.419 200.658C271.918 200.658 287.127 196.292 291.858 178.658Z"/>
      <path id="hairstyle-29" d="M215.796 43.2921C240.796 53.7921 267.119 75.1377 282.197 109.834C294.225 137.513 305.565 169.944 293.238 186.792C280.912 203.64 219.712 198.292 179.238 198.292C138.764 198.292 77.5651 203.64 65.2383 186.792C52.9115 169.944 64.5647 137.204 77.2798 109.834C92.0964 77.9399 112.201 46.8486 139.238 49.6177C152.796 35.7921 190.796 32.7922 215.796 43.2921Z"/>
      <path id="hairstyle-30" d="M148.71 38.4028C128.795 42.4028 94.1505 72.1476 77.7621 107.042C64.8303 134.576 53.3938 167.152 65.7206 184C78.0474 200.848 139.247 195.5 179.721 195.5C220.194 195.5 281.394 200.848 293.721 184C306.047 167.152 294.611 134.576 281.679 107.042C265.291 72.1476 230.646 42.4028 210.731 38.4028C190.816 34.4028 179.721 40.2284 179.721 40.2284C179.721 40.2284 168.625 34.4028 148.71 38.4028Z"/>
      <path id="hairstyle-31" d="M233.858 51.6177C265.747 79.1048 254.858 98 270.858 111.5C286.858 125 280.095 134.5 283.976 146.5C287.858 158.5 301.588 162.867 296.858 180.5C292.127 198.134 271.918 202.5 179.419 202.5C86.9196 202.5 66.7103 198.134 61.9797 180.5C57.2491 162.867 70.9797 158.5 74.8611 146.5C78.7425 134.5 66.0574 126.022 82.9797 111.5C95.798 100.5 95.3578 47 139.734 49.6177C147.292 37.7921 201.968 24.1306 233.858 51.6177Z"/>
      <path id="hairstyle-32" d="M62.0032 140.5C67.0767 125.914 79.2368 128.5 83.6024 111.243C96.5236 60.1676 128.636 42.4028 148.55 38.4028C168.465 34.4028 179.561 40.2284 179.561 40.2284C179.561 40.2284 190.657 34.4028 210.571 38.4028C230.486 42.4028 262.598 60.1676 275.519 111.243C279.885 128.5 292.045 125.914 297.119 140.5C301.119 152 295.119 161.5 299.119 173.5C302.66 184.123 311.284 195.181 308.619 208.5C303.888 232.133 282.06 236.5 179.561 236.5C77.0618 236.5 55.2338 232.133 50.5032 208.5C47.8373 195.181 56.4622 184.123 60.0032 173.5C64.0032 161.5 58.0032 152 62.0032 140.5Z"/>
      <path id="hairstyle-33" d="M215.09 43.2921C240.09 53.7921 267.57 81.7583 285.491 129.834C303.412 177.91 304.859 209.944 292.533 226.792C280.206 243.64 219.006 238.292 178.533 238.292C138.059 238.292 76.8594 243.64 64.5326 226.792C52.2058 209.944 58.8956 169.997 71.5741 129.834C84.2525 89.6707 111.495 46.8486 138.533 49.6177C152.09 35.7921 190.09 32.7922 215.09 43.2921Z"/>
      <path id="hairstyle-34" d="M148.632 38.4028C128.718 42.4028 95.6057 65.9662 77.6845 117.042C59.7633 168.118 53.3163 207.152 65.6431 224C77.9698 240.848 139.169 235.5 179.643 235.5C220.117 235.5 281.316 240.848 293.643 224C305.97 207.152 299.523 168.118 281.601 117.042C263.68 65.9662 230.568 42.4028 210.653 38.4028C190.739 34.4028 179.643 40.2284 179.643 40.2284C179.643 40.2284 168.547 34.4028 148.632 38.4028Z"/>
      <path id="hairstyle-35" d="M301.395 159.5C295.895 141.5 281.618 140.257 275.796 117.243C262.875 66.1676 241.395 53.7921 216.395 43.2921C191.395 32.7922 153.395 35.7921 139.837 49.6177C116.8 46.8486 95.6708 71.08 83.9923 117.243C78.1703 140.257 63.8931 141.5 58.3931 159.5C52.8931 177.5 61.237 187.091 56.3949 204.5C51.6103 221.702 37.3931 232.5 45.3949 258C53.3966 283.5 85.395 289.5 179.894 289.5C274.393 289.5 306.391 283.5 314.393 258C322.395 232.5 308.178 221.702 303.393 204.5C298.551 187.091 306.895 177.5 301.395 159.5Z"/>
      <path id="hairstyle-36" d="M301.395 156.5C295.895 138.5 281.618 137.257 275.796 114.243C262.875 63.1676 230.763 42.4028 210.848 38.4028C191.757 34.5682 180.77 39.7633 179.894 40.1996C179.018 39.7633 168.032 34.5682 148.94 38.4028C129.026 42.4028 96.9135 63.1676 83.9923 114.243C78.1703 137.257 63.8931 138.5 58.3931 156.5C52.8931 174.5 61.237 184.091 56.3949 201.5C51.6103 218.702 37.3931 229.5 45.3949 255C53.3966 280.5 85.395 286.5 179.894 286.5C274.393 286.5 306.391 280.5 314.393 255C322.395 229.5 308.178 218.702 303.393 201.5C298.551 184.091 306.895 174.5 301.395 156.5Z"/>
      <path id="hairstyle-37" d="M66.1189 283.755C45.907 251.756 70.2263 168.044 83.107 117.044C94.7854 70.8805 114.708 46.4448 138.745 49.2138C152.303 35.3883 187.245 32.7138 217.245 44.2138C247.245 55.7138 262.143 73.0437 277.024 127.044C291.905 181.044 312.859 251.756 292.647 283.755C272.435 315.753 219.73 295.238 179.383 295.238C139.036 295.238 86.3307 315.753 66.1189 283.755Z"/>
      <path id="hairstyle-38" d="M148.465 38.4007C128.613 42.3948 95.6047 63.3299 82.724 114.33C69.8432 165.33 45.524 249.042 65.7358 281.041C85.9477 313.04 138.653 292.524 179 292.524C219.347 292.524 272.052 313.04 292.264 281.041C312.476 249.042 288.157 165.33 275.276 114.33C262.395 63.3299 229.387 42.3948 209.535 38.4007C189.682 34.4066 179 40.2236 179 40.2236C179 40.2236 168.318 34.4066 148.465 38.4007Z"/>
      <path id="hairstyle-39" d="M56 169.591C58.6668 150.206 77.6618 142.848 83.4838 119.834C95.1622 73.6707 112.405 46.8486 139.442 49.6177C153 35.7921 191 32.7922 216 43.2922C241 53.7921 262.48 68.7583 275.401 119.834C281.223 142.848 300.331 150.206 302.998 169.591C305.672 189.023 298.156 189.682 302.998 207.091C307.783 224.293 326.598 230.318 329.998 256.591C332.214 273.71 323.998 277.091 326.998 295.091C329.998 313.091 347.998 311.591 347.998 350.091C347.998 388.591 303.998 448.591 179.499 448.591C55 448.591 11 388.591 11 350.091C11 311.591 29 313.091 32 295.091C35 277.091 26.7845 273.71 29 256.591C32.4001 230.318 51.2154 224.293 56 207.091C60.8422 189.682 53.3266 189.023 56 169.591Z"/>
      <path id="hairstyle-40" d="M56 164C58.6668 144.615 77.6618 137.257 83.4838 114.243C96.405 63.1676 128.517 42.4028 148.432 38.4028C168.347 34.4028 179.442 40.2284 179.442 40.2284C179.442 40.2284 190.538 34.4028 210.453 38.4028C230.368 42.4028 262.48 63.1676 275.401 114.243C281.223 137.257 300.331 144.615 302.998 164C305.672 183.433 298.156 184.091 302.998 201.5C307.783 218.702 326.598 224.728 329.998 251C332.214 268.119 323.998 271.5 326.998 289.5C329.998 307.5 347.998 306 347.998 344.5C347.998 383 303.998 443 179.499 443C55 443 11 383 11 344.5C11 306 29 307.5 32 289.5C35 271.5 26.7845 268.119 29 251C32.4001 224.728 51.2154 218.702 56 201.5C60.8422 184.091 53.3266 183.433 56 164Z"/>
      <path id="hairstyle-41" d="M76.6381 396.792C19.7166 328.26 70.7584 170.91 83.6796 119.834C95.358 73.6707 112.601 46.8486 139.638 49.6177C153.196 35.7921 191.196 32.7922 216.196 43.2921C241.196 53.7921 262.675 68.7583 275.597 119.834C288.518 170.91 339.56 328.26 282.638 396.792C225.717 465.324 133.56 465.324 76.6381 396.792Z"/>
      <path id="hairstyle-42" d="M148.628 38.4028C128.713 42.4028 96.6008 63.369 83.6796 114.445C70.7584 165.52 19.7166 322.871 76.6381 391.403C133.56 459.935 225.717 459.935 282.638 391.403C339.56 322.871 288.518 165.52 275.597 114.445C262.675 63.369 230.563 42.4028 210.649 38.4028C190.734 34.4028 179.638 40.2284 179.638 40.2284C179.638 40.2284 168.542 34.4028 148.628 38.4028Z"/>

      {/* EARS NEW */}
      <path id="ears-1" d="M15.3712 34.5309C15.3712 17.8077 21.2096 17.995 29.5498 17.995C39 17.995 46.3387 20.9808 46.3387 38.1442C46.3387 55.3077 41 60.278 33.5975 60.278C19.3712 60.278 15.3712 52.8077 15.3712 34.5309Z"/>
      <path id="ears-2" d="M13.151 29.5111C12.5702 20.5876 19.6708 14.7629 27.396 15.6919C33.7008 16.45 45.4193 23.1653 46 37.0888C46.5808 51.0123 46 62.3101 31.3064 62.3101C16.6128 62.3101 13.7317 36.4346 13.151 29.5111Z"/>
      <path id="ears-3" d="M18.8689 26.0524C19.6438 17.2674 26.8304 15.5621 33.4152 17.1364C40 18.7106 46 22.0444 46 35.568C46 53.568 39.7502 60.9386 30.8714 60.1824C21.9926 59.4261 24.9689 50.3823 23.398 45.3999C21.8272 40.4174 18.094 34.8375 18.8689 26.0524Z"/>
      <path id="ears-4" d="M15.2753 44.2352C16.7146 52.0905 21.6209 59.8938 28.1015 59.8938C36 59.8938 46 55.5 46 44.2352C46 26.5 39.858 18 28.1015 18C16.345 18 12.4588 28.8632 15.2753 44.2352Z"/>
      <path id="ears-5" d="M11.0018 33.1034C11.0018 19.2755 23.4098 16.889 30 18.2626C35.3785 19.3836 44.5 24.6893 45.5 34.7626C46.5 44.8358 45.5 59 31.2768 59C17.0536 59 11.0018 39.1767 11.0018 33.1034Z"/>
      <path id="ears-6" d="M15.3712 28.1619C14.7884 18.539 21.2096 11.6259 29.5498 11.6259C39 11.6259 46.3387 16.6118 46.3387 33.7752C46.3386 50.9386 50 64.909 29.5975 64.909C19.195 64.909 15.8958 36.8259 15.3712 28.1619Z"/>

      {/* FRINGES NEW */}
      <path id="fringe-1" d="M260 214.977H180H100V144.977H121.667C124.333 100.727 140 94.9772 140 94.9772V144.977H260V214.977Z"/>
      <path id="fringe-2" d="M260 214.977H100V144.977C112.171 144.977 121.878 135.396 124.441 123.498C129.777 98.7295 140 94.9772 140 94.9772V104.977C140 127.069 157.909 144.977 180 144.977H260V214.977Z"/>
      <path id="fringe-3" d="M126.529 138.245C137.128 139.221 161.357 139.977 180 139.977C198.643 139.977 222.872 139.221 233.471 138.245C248.492 136.862 260 144 260 158.977V214.977H180H100V158.977C100 144 111.508 136.862 126.529 138.245Z"/>
      <path id="fringe-4" d="M126.529 128.245C137.128 129.221 161.357 129.977 180 129.977C198.643 129.977 222.872 129.221 233.471 128.245C248.492 126.862 260 134 260 148.977V214.977H180H100V148.977C100 134 111.508 126.862 126.529 128.245Z"/>
      <path id="fringe-5" d="M260 214.977H100V148.977C100 137.931 109.406 130.047 119.654 125.924C131.787 121.043 135.941 110.431 140 94.9772C140 94.9772 163.6 93.6454 176.667 111.977C191.717 133.092 215.842 132.619 237 133C252.379 133 260 141.808 260 153.937V214.977Z"/>
      <path id="fringe-6" d="M260 214.977H100V154.977C100 141.17 111.358 130.315 124.766 127.016C146.713 121.615 165.258 111.21 180 94.9772C194.742 111.21 213.287 121.615 235.234 127.016C248.642 130.315 260 141.17 260 154.977V214.977Z"/>
      <path id="fringe-7" d="M100 214.977H260V157.977C260 142.513 247.441 130.216 232.006 129.267C196.124 127.059 172.301 118.836 140 92.9772C132.147 105.159 124.293 113.479 117.651 119.092C108.198 127.079 100 138.457 100 150.833V214.977Z"/>
      <path id="fringe-8" d="M100 214.977H260V140.081C182.671 159.118 172.08 92 120 92C108.357 114.109 100 136.687 100 162.116V214.977Z"/>
      <path id="fringe-9" d="M260 156.081V214.977H100V156.081C100 147.245 107.326 140.291 115.743 137.6C128.994 133.363 140.198 124.062 145.94 112.5C153.857 96.5607 167.804 89.3338 180 101C192.196 89.3338 206.143 96.5607 214.06 112.5C219.802 124.062 231.006 133.363 244.257 137.6C252.674 140.291 260 147.245 260 156.081Z"/>
      <path id="fringe-10" d="M100 214.977H260V161.5C260 95 169.405 120.5 140 94.9772C128 111 100 124.977 100 153.799V214.977Z"/>
      <path id="fringe-11" d="M100 214.977H180H260V166.577C260 95.8716 203.333 124.977 180 94.9772C156.667 124.977 99.9999 95.8716 100 166.577V214.977Z"/>
      <path id="fringe-12" d="M100 214.977H260V161.5C260 112 169.405 150.5 140 94.9772C137.739 113.932 128.019 126.344 118.728 134.063C108.73 142.369 100 153.801 100 166.799V214.977Z"/>
      <path id="fringe-13" d="M100 214.977H180H260V166.577C260 116.872 193.333 124.977 180 94.9772C166.667 124.977 99.9999 116.872 100 166.577V214.977Z"/>
      <path id="fringe-14" d="M100 214.977H180H260V164.577C260 124.872 154.5 158.523 140 95C127.5 158.523 99.9999 134.872 100 164.577V214.977Z"/>
      <path id="fringe-15" d="M100 214.977H180H260V164.577C260 134.872 194.5 148.523 180 95C167.5 148.523 99.9999 134.872 100 164.577V214.977Z"/>
      <path id="fringe-16" d="M100 214.977H180H260V174.977C260 125.272 140 91.9772 140 91.9772C140 91.9772 99.9999 125.272 100 174.977V214.977Z"/>
      <path id="fringe-17" d="M126.519 100.311C139.997 84.3326 157.911 98.8689 180 98.0781C202.089 98.8689 220.003 84.3326 233.481 100.311C240.191 108.266 241.993 125.565 241.993 125.565C258.667 135.112 260 149.229 260 162.875V214.977H100V162.875C100 149.229 101.333 135.112 118.007 125.565C118.007 125.565 119.809 108.266 126.519 100.311Z"/>
      <path id="fringe-18" d="M260 161.983L260 214.977H100V146.655C100 128.5 116.851 121.795 131.647 116.481C146.905 111 153.333 94.9772 153.333 94.9772C153.333 94.9772 160.229 110.103 177.905 116.481C190.905 121.171 200.243 113.684 212.405 121.171C223.807 128.19 219.04 134.7 233 139.978C233.396 140.128 234.22 140.392 234.22 140.392C247.195 144.34 260 146.861 260 161.983Z"/>
      <path id="fringe-19" d="M260 154.875L260 214.977H100L100 154.875C100 144.906 108.639 137.922 117.488 137.401C120.711 137.211 123.84 136.522 126.333 134.978C136.637 128.597 130.051 121.402 140.333 114.978C149.438 109.289 157.99 120.853 167 114.978C177.023 108.441 180 94.9772 180 94.9772C180 94.9772 182.977 108.441 193 114.978C202.01 120.853 210.562 109.289 219.667 114.978C229.949 121.402 223.363 128.597 233.667 134.978C236.16 136.522 239.289 137.211 242.512 137.401C251.361 137.922 260 144.906 260 154.875Z"/>
      <path id="fringe-20" d="M260 164.977V214.977L180 214.977L100 214.977V164.977C100 145.5 100.871 128.12 107.774 115.869C114.677 103.618 129.799 103.023 137.426 111.577C145.052 120.13 156.402 117.922 163.769 111.116C171.136 104.31 174.397 102.773 180 102.773C185.603 102.773 188.864 104.31 196.231 111.116C203.598 117.922 214.948 120.13 222.575 111.577C230.201 103.023 245.323 103.618 252.226 115.869C259.13 128.12 260 145.5 260 164.977Z"/>
      <path id="fringe-21" d="M100 131.977V214.977L260 214.977V131.977C260 131.977 253.58 146.092 240 142.352C222.936 137.654 221.333 120.977 221.333 120.977C221.333 120.977 216.351 143.15 198.667 142.352C181.324 141.57 180 118.977 180 118.977C180 118.977 178.676 141.57 161.333 142.352C143.649 143.15 138.667 120.977 138.667 120.977C138.667 120.977 137.064 137.654 120 142.352C106.42 146.092 100 131.977 100 131.977Z"/>
      <path id="fringe-22" d="M100 214.977H180H260C260 214.977 259.998 176.594 259.999 152V151.995C259.999 148.497 259.999 144.499 256.5 142C253 139.5 248.999 141.294 244.5 136.787C241.312 132.591 243.182 127.507 238.089 123.082C232.598 118.311 227.903 122.252 222.5 118.891C217.845 115.995 217.967 110.705 212 109C205 107 201.438 112.123 195 110.855C187.968 109.471 187.66 103.115 180 103.115C172.339 103.115 172.032 109.471 165 110.855C158.562 112.123 155 107 148 109C142.033 110.705 142.155 115.995 137.5 118.891C132.097 122.252 127.402 118.311 121.911 123.082C116.818 127.507 118.688 132.591 115.5 136.787C111.001 141.294 107 139.5 103.5 142C100.001 144.499 100.002 148.496 100.002 151.995V152C100.002 176.594 100 214.977 100 214.977Z"/>
      <path id="fringe-23" d="M260 214.977H180H100L100.002 141.89C112.037 146.866 126.06 134.131 121.912 121.082C132.957 126.514 148.573 119.242 148.32 106.056C158.432 117.039 179.821 112.998 180 97.1152C180.179 112.998 201.568 117.039 211.68 106.056C211.427 119.242 227.043 126.514 238.089 121.082C233.941 134.131 247.963 146.866 259.998 141.89L260 214.977Z"/>
      <path id="fringe-24" d="M100 163.679C100 122.637 122.436 92.7451 153.699 92.9772C157.71 104.543 168.927 110.635 180 111.254C191.073 110.635 202.29 104.543 206.301 92.9772C237.564 92.7451 260 122.637 260 163.679V214.977L180 214.977H100V163.679Z"/>
      <path id="fringe-25" d="M100 141.382V214.977H180L260 214.977V141.382C260 121.696 253.696 102.638 235.932 96.9051C220.09 91.7924 206.194 97.9636 196.488 106.024C187.795 113.244 172.205 113.244 163.512 106.024C153.806 97.9637 139.91 91.7924 124.068 96.9051C106.304 102.638 100 121.696 100 141.382Z"/>
      <path id="fringe-26" d="M260 214.977V150.761C260 69.1184 194.808 91.4626 180 111.463C165.192 91.4626 100 69.1184 100 150.761V214.977L260 214.977Z"/>
      <path id="fringe-27" d="M100 215H180H260V164.167C260 113.633 224.183 93 180 93C135.817 93 99.9999 113.633 100 164.167V215Z"/>

      {/* JAWLINES NEW */}
      <path id="jawline-1" d="M180 185H100V215C100 245.75 119.28 278.115 130 290.375C140.72 302.635 151.281 305 180 305C208.719 305 219.28 302.635 230 290.375C240.72 278.115 260 245.75 260 215V185H180Z"/>
      <path id="jawline-2" d="M180 185H100V215C100 245.75 124.28 278.115 135 290.375C145.72 302.635 155.281 305 180 305C204.719 305 214.28 302.635 225 290.375C235.72 278.115 260 245.75 260 215V185H180Z"/>
      <path id="jawline-3" d="M180 185H260V215C260 242.5 224.545 286.697 217 294.375C209.455 302.053 201.737 305 180 305C158.263 305 150.545 302.053 143 294.375C135.455 286.697 100 242.5 100 215V185L180 185Z"/>
      <path id="jawline-4" d="M180 185H260V215C260 242.5 222.545 283.322 216 291C209.455 298.678 203 305 180 305C157 305 150.545 298.678 144 291C137.455 283.322 100 242.5 100 215V185L180 185Z"/>
      <path id="jawline-5" d="M180 185H100V217C100 255.36 113.997 270.177 127.983 284.983L128 285C145.572 303.602 154.333 305 180 305C205.667 305 214.428 303.601 232 285L232.017 284.982C246.003 270.177 260 255.36 260 217V185L180 185Z"/>
      <path id="jawline-6" d="M180 185H100V215C100 253.375 119.008 270.189 133 285C150.572 303.602 161.333 305 180 305C198.667 305 209.428 303.601 227 285C240.992 270.188 260 253.375 260 215V185L180 185Z"/>
      <path id="jawline-7" d="M180 185H100V215C100 248.375 122.008 268.189 136 283C153.572 301.602 161.333 305 180 305C198.667 305 206.428 301.601 224 283C237.992 268.188 260 248.375 260 215V185L180 185Z"/>
      <path id="jawline-8" d="M180 185H260V215C260 245.375 232.992 268.189 219 283C201.428 301.601 196.667 305 180 305C163.333 305 158.572 301.601 141 283C127.008 268.189 100 245.375 100 215V185H180Z"/>
      <path id="jawline-9" d="M180 185H260V215C260 254.75 251.325 261.314 237.333 276.125C219.761 294.726 217.333 305 180 305C142.667 305 140.239 294.726 122.667 276.125C108.675 261.314 100 254.75 100 215V185H180Z"/>
      <path id="jawline-10" d="M180 185H260V215C260 254.75 247.325 261.314 233.333 276.125C215.761 294.726 213.333 305 180 305C146.667 305 144.239 294.726 126.667 276.125C112.675 261.314 100 254.75 100 215V185H180Z"/>
      <path id="jawline-11" d="M180 185H260V215C260 250.25 243.325 260.189 229.333 275C211.761 293.601 211 305 180 305C149 305 148.239 293.601 130.667 275C116.675 260.189 100 250.25 100 215V185L180 185Z"/>
      <path id="jawline-12" d="M180 185H260V215C260 245.375 236.992 263.189 223 278C205.428 296.601 206.667 305 180 305C153.333 305 154.572 296.601 137 278C123.008 263.189 100 245.375 100 215V185H180Z"/>
      <path id="jawline-13" d="M100 185V218C100 271.342 128.581 284.619 141 291C153.419 297.381 155 305 180 305C205 305 206.581 297.381 219 291C231.419 284.619 260 271.342 260 218V185H180H100Z"/>
      <path id="jawline-14" d="M100 185V215C100 267.342 131.581 284.619 144 291C156.419 297.381 159 305 180 305C201 305 203.581 297.381 216 291C228.419 284.619 260 267.342 260 215V185H180H100Z"/>
      <path id="jawline-15" d="M100 185V215C100 262.342 134.581 284.619 147 291C159.419 297.381 163 305 180 305C197 305 200.581 297.381 213 291C225.419 284.619 260 262.342 260 215V185H180H100Z"/>
      <path id="jawline-16" d="M100 185V215C100 258.342 137.581 284.619 150 291C162.419 297.381 165 305 180 305C195 305 197.581 297.381 210 291C222.419 284.619 260 258.342 260 215V185H180H100Z"/>
      <path id="jawline-17" d="M180 185L260 185V232C260 274 241.919 275.754 220 290.375C204.524 300.699 197.045 305 180 305C162.954 305 155.475 300.699 140 290.375C118.081 275.754 99.9995 274 99.9995 232V185L180 185Z"/>
      <path id="jawline-18" d="M180 185L260 185V228C260 269 239.919 273.754 218 289.375C202.851 300.172 196.045 305 180 305C163.954 305 157.149 300.172 142 289.375C120.081 273.754 99.9995 269 99.9995 228V185L180 185Z"/>
      <path id="jawline-19" d="M180 185L260 185V226C260 262 239.398 270.047 218 286.375C203.851 297.172 197.045 305 180 305C162.954 305 156.149 297.172 142 286.375C120.602 270.047 99.9995 262 99.9995 226V185L180 185Z"/>
      <path id="jawline-20" d="M180 185L260 185V226C260 252 238.802 266.295 218 283.375C204.851 294.172 198.045 305 180 305C161.954 305 155.149 294.172 142 283.375C121.197 266.295 99.9995 252 99.9995 226V185L180 185Z"/>
      <path id="jawline-21" d="M180 185L260 185V232C260 263 241.919 274.754 220 290.375C204.851 301.172 194.045 305 180 305C165.954 305 155.149 301.172 140 290.375C118.081 274.754 99.9995 263 99.9995 232V185L180 185Z"/>
      <path id="jawline-22" d="M180 185L260 185V225C260 256 239.919 273.754 218 289.375C202.851 300.172 194.045 305 180 305C165.954 305 157.149 300.172 142 289.375C120.081 273.754 99.9995 256 99.9995 225V185L180 185Z"/>
      <path id="jawline-23" d="M180 185L260 185V220C260 251 234.919 272.754 215 288.375C200.362 299.855 194.045 305 180 305C165.954 305 159.638 299.855 145 288.375C125.081 272.754 99.9995 251 99.9995 220V185L180 185Z"/>
      <path id="jawline-24" d="M180 185L260 185V215C260 246 229.919 271.754 212 287.375C197.978 299.6 194.045 305 180 305C165.954 305 162.022 299.6 148 287.375C130.081 271.754 99.9995 246 99.9995 215V185L180 185Z"/>
      <path id="jawline-25" d="M100 215C100 285 160 305 180 305C200 305 260 285 260 215V185H180H100V215Z"/>
      <path id="jawline-26" d="M100 215C100 275 160 305 180 305C200 305 260 275 260 215V185H180H100V215Z"/>
      <path id="jawline-27" d="M100 215C100 265 160 305 180 305C200 305 260 265 260 215V185H180H100V215Z"/>
      <path id="jawline-28" d="M100 215C100 255 160 305 180 305C200 305 260 255 260 215V185H180H100V215Z"/>
      <path id="jawline-29" d="M260 185V230C260 282 222.667 305 180 305C137.333 305 100 282 100 230V185H260Z"/>
      <path id="jawline-30" d="M260 185V230C260 280 216.667 305 180 305C143.333 305 100 280 100 230V185H260Z"/>
      <path id="jawline-31" d="M260 230C260 275 211.667 305 180 305C148.333 305 100 275 100 230V185H180H260V230Z"/>
      <path id="jawline-32" d="M100 230C100 260 143.333 305 180 305C216.667 305 260 260 260 230V185H180H100V230Z"/>
      <path id="jawline-33" d="M100 185V215C100 267 129.333 305 180 305C230.667 305 260 267 260 215V185H100Z"/>
      <path id="jawline-34" d="M100 185V215C100 265 135.333 305 180 305C224.667 305 260 265 260 215V185H100Z"/>
      <path id="jawline-35" d="M100 185V215C100 255 137.333 305 180 305C222.667 305 260 255 260 215V185H100Z"/>
      <path id="jawline-36" d="M100 185V215C100 245 139.333 305 180 305C220.667 305 260 245 260 215V185H100Z"/>


      {/* WRINKLES FOREHEAD NEW */}
      <path id="wrinklesforehead-1" d="M46 25C33.905 25 27 20.0001 27 20.0001" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesforehead-2" d="M46 15.0001C20.7849 15.0001 6.38956 6.57628 6.38956 6.57628" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesforehead-3" d="M33.9999 29C33.4949 20.3442 28.905 15.1938 28.905 15.1938" stroke-linecap="round" stroke-linejoin="round"/>

      {/* WRINKLES CHEEKBONES NEW */}
      <path id="wrinklescheekbones-1" d="M32.9639 78.25C26.9639 72.25 28.0237 60 28.0237 55.4847C28.0237 50.9694 25.2994 43.25 25.2994 43.25" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-2" d="M29.5 64C29.5 64 29.5 46.5 20.8624 35.2366" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-3" d="M34.5 75.5C29.5578 70.5578 27.5693 64.9488 26.9086 60.159C25.9793 53.4215 23.7573 46.1805 19.4791 40.8933C13.5501 33.5661 6.5 21.2307 6.5 4" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-4" d="M36.811 70.0535C37.2911 44.8565 6.5 29.1246 6.5 4" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-5" d="M25.6612 66C26.8847 54.9674 22.4654 31.9217 7 12" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-6" d="M23.5 60C23.2662 28.1115 6.51973 31.3637 6.5 4" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-7" d="M23.4047 55.9999C23.4047 55.9999 25.6656 44.9832 17.889 37.4865C10.1124 29.9899 7.31581 23.875 6.5 12" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-8" d="M6.5 22C6.5 35.5 17.7876 43.2084 28 45" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklescheekbones-9" d="M6.5 4C6.5 32.5 21.1063 45.4541 21.1063 45.4541" stroke-linecap="round" stroke-linejoin="round"/>

      {/* WRINKLES MOUTH NEW */}
      <path id="wrinklesmouth-1" d="M19.6727 34C12.6727 44.5 21.4836 56.659 21.4836 56.659" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-2" d="M12.4828 23.587C7.3425 35.125 4.40501 43 16.0173 56.413" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-3" d="M18.3359 17.8959C8.70199 25.6356 7.61261 43.6521 7.61261 43.6521" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-4" d="M19.75 18C14.75 21.75 14.75 25.2 14.75 41" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-5" d="M24.5 7C23.5328 23.4416 4.0931 19.4581 11.5784 38.9309" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-6" d="M30 8.00005C29.3859 18.439 11.4435 23.9654 11.4435 23.9654C11.4435 23.9654 7.86342 30.2665 11.5784 39.9309" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-7" d="M8 30.5C10.5 19.5 27.5 27 29.5 8.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-8" d="M28.1726 10.6875C23.1726 14.4375 13.1726 15.8875 13.1726 31.6875" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-9" d="M30 13C24.5 13 15.7954 31.8367 17.1344 47.5798" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-10" d="M7.05264 47.0834C16.6865 39.3436 11.0392 15.2979 28.9701 9" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-11" d="M21.2796 27.7562C28.9135 21.0165 30 11 30 11" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinklesmouth-12" d="M10 19.5C25.5243 19.9297 30.0694 10.7573 30.0694 10.7573" stroke-linecap="round" stroke-linejoin="round"/>

      {/* WRINKLES CHIN NEW */}
      <path id="wrinkleschin-1" d="M32.0915 37.5135V24.0135" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-2" d="M32.0915 37.0136C29.0916 37.0135 25.5915 23.0135 32.0915 23.0135" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-3" d="M32.0915 29.0135C28.0915 29.0135 28.0915 23.0135 32.0915 23.0135" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-4" d="M32.0916 38.0135C12.0915 38.0135 8.59154 30.0135 6 19" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-5" d="M32.0915 38.0135C21.7165 38.0135 15.625 33.5 12.75 25.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-6" d="M32.0915 7.01352C17.5972 7.41413 18 21.4999 18 21.4999" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-7" d="M32.0915 7.15439C23.5915 7.15441 17.4295 6.33349 11.9435 11.2201" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleschin-8" d="M32.0915 7C22.1865 7 16.3908 11.061 16.3908 11.061" stroke-linecap="round" stroke-linejoin="round"/>

      {/* WRINKLES EYES NEW */}
      <path id="wrinkleseyes-1" d="M24.4064 39.6861C29.7272 38.6861 32.7103 38.6861 38.0311 39.6861" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-2" d="M24.4064 36.9361C29.7272 38.9361 32.7103 38.9361 38.0311 36.9361" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-3" d="M30.008 40.8263C40.0769 39.8027 45.1843 37.8338 53.3348 31.834" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-4" d="M29 42C18.9312 40.9764 11 33.5 11 21" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-5" d="M29.5 38.5C12.4312 38.4764 9.00002 30 9.00002 30" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-6" d="M12 23C12 23 5.1242 23.9526 2.00002 19" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-7" d="M12 23.3576C12 23.3576 5.1242 22.4049 2.00002 27.3576" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-8" d="M2.00001 27.3576C5.1242 22.4049 12 23.1788 12 23.1788C12 23.1788 5.1242 23.9526 2.00001 19" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-9" d="M37.5311 42.7568C57.7262 39.6287 57.5311 23.2632 57.5311 23.2632" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="wrinkleseyes-10" d="M47.5311 41.6382C57.7811 34.8882 57.5311 23.2632 57.5311 23.2632" stroke-linecap="round" stroke-linejoin="round"/>

      {/* UPPER LIP NEW */}
      <path id="upperlip-1" d="M38 15.5C32 15.5 30.1648 14.7385 25 14C18.0075 13.0001 14 14.5 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C62 14.5 57.9925 13.0001 51 14C45.8352 14.7385 44 15.5 38 15.5Z"/>
      <path id="upperlip-2" d="M38 15.5C35 15.5 34.1993 14.4335 28 14C15.9952 13.1605 15 14.5 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C61 14.5 60.0048 13.1605 48 14C41.8007 14.4335 41 15.5 38 15.5Z"/>
      <path id="upperlip-3" d="M38 19C25.3445 19 6 14 6 14C13 14.5 24.9669 13.1943 32 13.9828C36.1016 14.4426 36 15.5 38 15.5C40 15.5 39.8984 14.4426 44 13.9828C51.0331 13.1943 63 14.5 70 14C70 14 50.6555 19 38 19Z"/>
      <path id="upperlip-4" d="M38 17C38 17 30.4174 13.9999 22 14H6C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14H54C45.5826 13.9999 38 17 38 17Z"/>
      <path id="upperlip-5" d="M38 16.5C38 16.5 36 14 27 14C20.1409 14 13.9003 14.7996 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C62.0997 14.7996 55.8591 14 49 14C40 14 38 16.5 38 16.5Z"/>
      <path id="upperlip-6" d="M38 16C38 16 38.5826 13.9999 44 14C51.8591 14.0001 59.2898 15.7819 70 14C70 14 50.6555 19 38 19C25.3445 19 6 14 6 14C16.7102 15.7819 24.1409 14.0001 32 14C37.4174 13.9999 38 16 38 16Z"/>
      <path id="upperlip-7" d="M53 13L70 14C70 14 50.6431 18.9967 38 19.0001C25.3569 18.9967 6 14 6 14L23 13C25 13 38 16.0001 38 16.0001C38 16.0001 51 13 53 13Z"/>
      <path id="upperlip-8" d="M47 13C49 13 60.4532 14.8981 70 14C70 14 50.6431 18.9967 38 19.0001C25.3569 18.9967 -3.54681 13.1019 6.00001 14C15.5468 14.8981 27 13 29 13C31 13 38 15.5 38 15.5C38 15.5 45 13 47 13Z"/>
      <path id="upperlip-9" d="M43 13C45 13 60.4532 15.8981 70 14C70 14 50.6431 18.9967 38 19.0001C25.3569 18.9967 6 14 6 14C15.5468 15.8981 31 13 33 13C35 13 38 14.9983 38 14.9983C38 14.9983 41 13 43 13Z"/>
      <path id="upperlip-10" d="M38 12C33.2589 12 30.1507 9.99995 24 10C14.0075 10.0001 6 14 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C70 14 61.9925 10.0001 52 10C45.8493 9.99995 42.7411 12 38 12Z"/>
      <path id="upperlip-11" d="M38 12C36 12 33.2174 9.99995 28 10C18.0075 10.0001 6 14 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C70 14 57.9925 10.0001 48 10C42.7826 9.99995 40 12 38 12Z"/>
      <path id="upperlip-12" d="M38 12C37 12 36.2174 9.99995 31 10C21.0075 10.0001 12.0416 13.1733 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C63.9584 13.1733 54.9925 10.0001 45 10C39.7826 9.99995 39 12 38 12Z"/>
      <path id="upperlip-13" d="M38 15C38 15 32.4174 9.99993 24 10C16.1409 10.0001 13.4062 13.1012 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C62.5938 13.1012 59.8591 10.0001 52 10C43.5826 9.99993 38 15 38 15Z"/>
      <path id="upperlip-14" d="M38 15C38 12.8667 34.5729 10 29 10C19.9494 10 14.735 12.8167 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C61.265 12.8167 56.0506 10 47 10C41.4271 10 38 12.8667 38 15Z"/>
      <path id="upperlip-15" d="M31 10.0005C25.0689 9.9497 21.7855 13.0946 6 14.0005C6 14.0005 25.3308 19.0005 38 19.0005C50.6692 19.0005 70 14.0005 70 14.0005C54.2145 13.0946 50.9311 9.94969 45 10.0005C39.0689 10.0512 38 14.0005 38 14.0005C38 14.0005 36.9311 10.0512 31 10.0005Z"/>
      <path id="upperlip-16" d="M52 10.0005C54.7259 9.95053 70 14.0005 70 14.0005C70 14.0005 50.6645 19.0039 38 19.0005C25.3569 18.9971 6 14.0005 6 14.0005C6 14.0005 21.2741 9.95053 24 10.0005C26.7259 10.0504 38 15.0005 38 15.0005C38 15.0005 49.2741 10.0504 52 10.0005Z"/>
      <path id="upperlip-17" d="M48 10.0008C49.6593 9.95082 64.2627 12.4821 70 14.0007C70 14.0007 50.6645 19.0041 38 19.0007C25.3569 18.9973 6 14.0007 6 14.0007C11.7373 12.4821 26.3407 9.9508 28 10.0007C29.6593 10.0507 38 15.0007 38 15.0007C38 15.0007 46.3407 10.0507 48 10.0008Z"/>
      <path id="upperlip-18" d="M32 10C29.8667 10 24.6707 11.4813 6 14C6 14 25.3569 18.9966 38 19C50.6431 18.9966 70 14 70 14C51.3293 11.4813 46.1333 10 44 10C42.1037 10 38 15 38 15C38 15 33.8963 10 32 10Z"/>
      <path id="upperlip-19" d="M38 9C33.2589 9 29.1507 6.99995 25 7C15.0075 7.00013 6 14 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C70 14 60.9925 7.00013 51 7C46.8493 6.99995 42.7411 9 38 9Z"/>
      <path id="upperlip-20" d="M38 9C35.3255 9 33.1507 6.99995 29 7C19.0075 7.00013 6 14 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C70 14 56.9925 7.00013 47 7C42.8493 6.99995 40.6745 9 38 9Z"/>
      <path id="upperlip-21" d="M38 9C37 9 36 6.99995 32 7C22.0075 7.00013 6 14 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C70 14 53.9925 7.00013 44 7C39.8493 6.99995 39 9 38 9Z"/>
      <path id="upperlip-22" d="M38 12C38 12 33.4174 6.99993 25 7C17.1409 7.00006 11.3333 11.1067 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C64.6667 11.1067 58.8591 7.00006 51 7C42.5826 6.99993 38 12 38 12Z"/>
      <path id="upperlip-23" d="M38 12C37 9.86666 34.5729 7 29 7C20.9494 7 17.3376 9.95064 6 14C6 14 25.3445 19 38 19C50.6555 19 70 14 70 14C58.6624 9.95064 55.0506 7 47 7C41.4271 7 39 9.86666 38 12Z"/>
      <path id="upperlip-24" d="M32 7.00027C26.0689 6.94951 18.5855 12.0277 6 14.0003C6 14.0003 25.3308 19.0003 38 19.0003C50.6692 19.0003 70 14.0003 70 14.0003C57.4145 12.0277 49.9311 6.94951 44 7.00027C38.0689 7.05103 38 12.0003 38 12.0003C38 12.0003 37.9311 7.05103 32 7.00027Z"/>
      <path id="upperlip-25" d="M52 7.00035C54.7259 6.95041 64.2627 10.3484 70 14.0003C70 14.0003 50.6645 19.0037 38 19.0003C25.3569 18.9969 6 14.0003 6 14.0003C11.7373 10.3484 21.2741 6.95041 24 7.00035C26.7259 7.05028 38 12.0003 38 12.0003C38 12.0003 49.2741 7.05028 52 7.00035Z"/>
      <path id="upperlip-26" d="M48 7.00035C51 7.00036 64.2627 11.3484 70 14.0004C70 14.0004 50.6645 19.0038 38 19.0004C25.3569 18.997 6 14.0004 6 14.0004C11.7373 11.3484 25 7.00096 28 7.00066C31 7.00035 38 12.0004 38 12.0004C38 12.0004 45 7.00034 48 7.00035Z"/>
      <path id="upperlip-27" d="M32 7C29.8667 7 22.404 10.348 6 14C6 14 25.3569 18.9966 38 19C50.6431 18.9966 70 14 70 14C53.596 10.348 46.1333 7 44 7C42.1037 7 38 12 38 12C38 12 33.8963 7 32 7Z"/>

      {/* LOWER LIP NEW */}
      <path id="lowerlip-1" d="M6 14C30 31.0852 26.6931 28.9104 38 28.9104C49.3069 28.9104 46 31.0852 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-2" d="M6 14C22 31.0852 22.6931 28.9104 38 28.9104C53.3069 28.9104 54 31.0852 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-3" d="M6 14C17 31.0852 15.6931 28.9104 38 28.9104C60.3069 28.9104 61 31.0852 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-4" d="M31 29C35 29 36.0646 28.007 38 28.007C39.9354 28.007 41 29 45 29C53.7232 29 58.2704 19.9277 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14C17.7296 19.9277 22.2768 29 31 29Z"/>
      <path id="lowerlip-5" d="M38 19C25.3858 19 6 14 6 14C11.8322 21.8731 18 29 28 29C31 29 34.5868 28 38 28C41.4132 28 45 29 48 29C58 29 64.1678 21.8731 70 14C70 14 50.6142 19 38 19Z"/>
      <path id="lowerlip-6" d="M6 14C9 27.014 17.328 29 27 29C32 29 34 27.9544 38 27.9544C42 27.9544 44 29 49 29C58.672 29 67 27.014 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-7" d="M38 19C25.3445 19 5.99997 14 5.99997 14C11.8333 20.6665 25.2633 28.9995 38 28.9995C50.7367 28.9995 64.1666 20.6665 70 14C70 14 50.6554 19 38 19Z"/>
      <path id="lowerlip-8" d="M38 19C25.3445 19 6 14 6 14C8.83333 20.6665 19 28.9995 38 28.9995C57 28.9995 67.1667 20.6665 70 14C70 14 50.6555 19 38 19Z"/>
      <path id="lowerlip-9" d="M37.9941 29C62.9705 29 67.1408 22.6699 69.9742 14.0066C69.1828 14.2098 50.3908 19 38.0059 19C25.3528 19 6.01184 14 6.01184 14C8.84527 22.6667 13.0113 29 37.9941 29Z"/>
      <path id="lowerlip-10" d="M6 14C30 36.1166 26.6931 33.9412 38 33.9412C49.3069 33.9412 46 36.1166 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-11" d="M6 14C22 36.1166 22.6931 33.9412 38 33.9412C53.3069 33.9412 54 36.1166 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-12" d="M6 14C17 36.1166 15.6931 33.9412 38 33.9412C60.3069 33.9412 59 36.1166 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-13" d="M45 33.6061C40.7805 34.5794 39.9354 33.4426 38 33.4426C36.0646 33.4426 35.2195 34.5794 31 33.6061C22.5 31.6456 15.7296 21.9696 6 14C6 14 25.3858 19 38 19C50.6142 19 70 14 70 14C60.2704 21.9696 53.5 31.6456 45 33.6061Z"/>
      <path id="lowerlip-14" d="M6 14C13.8305 25.4088 17.8094 32.525 27.3984 33.7889C33.0801 34.5379 32.2441 33.053 38 33.053C43.7559 33.053 42.9199 34.5379 48.6016 33.7889C58.1906 32.525 62.1695 25.4088 70 14C70 14 50.6142 19 38 19C25.3858 19 6 14 6 14Z"/>
      <path id="lowerlip-15" d="M38 19C25.3858 19 6 14 6 14C9.83221 24.8731 12 34 27 34C30 34 33.5868 33 38 33C42.4132 33 46 34 49 34C64 34 66.1678 24.8731 70 14C70 14 50.6142 19 38 19Z"/>
      <path id="lowerlip-16" d="M38 19C25.3445 19 5.99997 14 5.99997 14C11.8333 20.6665 25.2633 33.9995 38 33.9995C50.7367 33.9995 64.1666 20.6665 70 14C70 14 50.6554 19 38 19Z"/>
      <path id="lowerlip-17" d="M38 19C25.3445 19 6 14 6 14C8.83333 20.6665 19 33.9995 38 33.9995C57 33.9995 67.1667 20.6665 70 14C70 14 50.6555 19 38 19Z"/>
      <path id="lowerlip-18" d="M37.9941 34C62.9705 34 67.1408 22.6725 69.9742 14.0066C69.1828 14.2098 50.3908 19 38.0059 19C25.3528 19 6.01184 14 6.01184 14C8.84527 22.6667 13.0113 34 37.9941 34Z"/>

      {/* SMILE NEW */}
      <path id="smile-1" d="M6 13C17 17.5 32 19.0013 38 19C44 19.0013 59 17.5 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-2" d="M6 13C10.1867 16.9905 15.4915 16.6607 23.5 17C28.8813 17.228 38 18.1472 38 18.1472C38 18.1472 47.1187 17.228 52.5 17C60.5085 16.6607 65.8133 16.9905 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-3" d="M6 13C10.1867 15.9905 20.4915 16.6607 28.5 17C33.8813 17.228 38 18.1472 38 18.1472C38 18.1472 42.1187 17.228 47.5 17C55.5085 16.6607 65.8133 15.9905 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-4" d="M6 13C6 13 7.85507 16.7102 16.6667 16.7102C26.4058 16.7102 36.0355 18.9565 38 18.9565C39.9645 18.9565 49.5942 16.7101 59.3333 16.7101C68.1449 16.7101 70 13 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-5" d="M6 13C6 13 7 16.9701 21.6487 16.812C27.3312 16.7507 30.2976 19.5 37.9982 19.5C45.6987 19.5 48.6651 16.7507 54.3476 16.812C68.9963 16.9701 70 13 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-6" d="M70 13C68 16 61.0244 15.6236 56 16.5C51.9352 17.209 43.3394 19.5 38 19.5C32.6606 19.5 24.0648 17.209 20 16.5C14.9756 15.6236 8 16 6 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-7" d="M6 13C9.60215 16.1905 18.9756 15.6236 24 16.5C28.0648 17.209 32.6606 19.5 38 19.5C43.3394 19.5 47.9352 17.209 52 16.5C57.0244 15.6236 66.3978 16.1905 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-8" d="M6 13C9 16 18.7665 17.3868 26.3182 17.3868C30.8698 17.3868 34.1679 19 38 19C41.8321 19 45.1302 17.3868 49.6818 17.3868C57.2335 17.3868 67 16 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-9" d="M6 13C6 13 8.37691 17.3861 25 17.5C31.5622 17.545 34.4368 20.5 38 20.5C41.5632 20.5 44.4378 17.545 51 17.5C67.6231 17.3861 70 13 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-10" d="M6 13C6 13 10.3725 18 29 18C36 18 35 20 38 20C41 20 40 18 47 18C65.6275 18 70 13 70 13" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="smile-11" d="M6 13C12.8971 17.1383 27.5 15.5 38 20C48.5 15.5 63.1029 17.1383 70 13" stroke-linecap="round" stroke-linejoin="round"/>

      {/* MUSTACHIO NEW */}
      <path id="mustachio-12" d="M18 35C18 19.536 30.536 7 46 7H59.235C61.7055 7 64.1365 7.61988 66.3053 8.80287C71.0487 11.3902 74 16.3618 74 21.765V37L67 27L60 37L53 27L46 37L39 27L32 37L25 27L18 37V35Z"/>
      <path id="mustachio-11" d="M0 36.9462C18.0942 39.539 49.2662 -10.6964 69.4722 11.8239C69.4722 11.8239 72.6333 15.667 63.4833 26.3066C54.3334 36.9462 36.6647 45 21.699 45C6.73332 45 0 36.9462 0 36.9462Z"/>
      <path id="mustachio-10" d="M40.155 17.9261C47.5273 11.7177 56.1398 7.3208 65.0921 7.0008C73.6761 6.69397 78.7252 16.9167 67.3625 22.4606C53.7931 29.0811 41.2892 32.0169 41.2892 32.0169C41.2892 34.919 42.4349 66.3321 36.7117 66.0321C30.7385 65.719 32.0944 35.5654 32.2156 32.0169C32.3796 27.2173 32.7827 24.1345 40.155 17.9261Z"/>
      <path id="mustachio-9" d="M74 8.67484C54.0952 8.67484 30 8.67484 30 32.6748C38.7738 14.4679 74 28.1231 74 8.67484Z"/>
      <path id="mustachio-8" d="M37 29.6748C37 29.6748 37 15.4612 62.9934 13.0273C70.3924 12.6154 71.2664 16.3834 69.8174 20.5573C69.3871 21.7967 68.8003 22.8946 68 23.5839C58.8092 31.5 37 29.6748 37 29.6748Z"/>
      <path id="mustachio-7" d="M74 29C74 29 54 35 28 35C28 35 27.9523 10.8497 43 5.69344C49.7591 3.37736 60.362 4.8992 74 13.5V29Z"/>
      <path id="mustachio-6" d="M59.9674 21.2016C52.8828 19.3652 43.3888 13.905 39 4.71304C47.0559 11.8564 67.1815 10.0751 71 19.3386C71 19.3386 67.052 23.038 59.9674 21.2016Z"/>
      <path id="mustachio-5" d="M21.8592 32.0387C35.5 34 59 -4.00001 74.2329 13.0352C74.2329 13.0352 76.616 15.9423 69.718 23.9905C62.8201 32.0387 49.5 38.1309 38.2177 38.1309C26.9353 38.1309 21.8592 32.0387 21.8592 32.0387Z"/>
      <path id="mustachio-4" d="M62.6192 12.2686C74.2128 11.2622 73.9997 19.7724 73.9997 23.314C73.9997 23.314 69.0649 22.5744 61.8539 23.081C54.643 23.5876 36.7663 33.2408 27.8788 26.7825C22.7292 23.0405 21.4013 15.9956 25.1241 10.5145C27.935 6.37598 34.6089 5.44466 37.6297 10.1622C40.6504 14.8798 35.737 17.1547 33.7231 17.1547C33.7231 17.1547 36.7439 15.1016 35.2335 12.5353C33.7231 9.9689 30.1989 10.4822 29.269 13.1393C27.8258 17.2631 29.7116 21.8341 35.2511 22.5472C40.7906 23.2604 53.4806 13.0618 62.6192 12.2686Z"/>
      <path id="mustachio-3" d="M74 9.03261C51.381 9.03261 24 6.81281 24 39C44.2381 39 74 35.1153 74 9.03261Z"/>
      <path id="mustachio-2" d="M41 26C31 24 29 17 29 17C35.7443 19.3576 39 14 46 11C53 8 60 10 60 16C60 22 51 28 41 26Z"/>
      <path id="mustachio-1" d="M74 30.3279L74 9.54224C64.7606 3.38402 50.9163 5.63223 44.3426 14.042C24.1847 6.17844 10.1972 28.7517 13.4773 45.5994C13.4773 45.5994 28.9904 49.6435 36.1245 33.2131C44.577 38.1205 54.1615 34.4333 59.9144 27.0452C64.1151 29.6711 69.0995 30.8666 74 30.3279Z"/>

      {/* BEARD NEW */}
      <path id="beard-1" d="M12.2803 10C11.2803 70 11.4242 90.9644 40.1776 107C47.038 110.826 52.5756 114.234 57.3598 117.179C70.7071 125.394 78.1909 130 92.1775 130C106.164 130 113.648 125.394 126.995 117.179C131.779 114.234 137.317 110.826 144.177 107C172.931 90.9644 173.075 70 172.075 10C172.075 10 155.075 39 139.575 74C124.075 109 106.075 107 102.575 95.5C102.025 93.6924 101.557 92.0454 101.133 90.5531C98.8609 82.5517 97.8522 79 92.1775 79C86.5027 79 85.4941 82.5517 83.2219 90.5531C82.7981 92.0454 82.3304 93.6924 81.7803 95.5C78.2803 107 60.2803 109 44.7803 74C29.2802 39 12.2803 10 12.2803 10Z"/>
      <path id="beard-2" d="M12.1028 10C10.6396 22.3333 6.80224 65 15.581 81C23.2452 94.9687 38.3329 107.084 62.218 122.771C71.6447 128.962 80.4177 130 92 130C103.582 130 112.355 128.962 121.782 122.771C145.667 107.084 160.755 94.9687 168.419 81C177.198 65 173.36 22.3333 171.897 10C171.897 10 154.897 39 139.397 74C123.897 109 105.897 107 102.397 95.5C101.847 93.6924 101.379 92.0454 100.956 90.5532C98.6834 82.5517 97.6748 79 92 79C86.3252 79 85.3166 82.5517 83.0443 90.5532C82.6205 92.0454 82.1528 93.6924 81.6027 95.5C78.1028 107 60.1028 109 44.6027 74C29.1027 39 12.1028 10 12.1028 10Z"/>
      <path id="beard-3" d="M12.5418 10C9.43907 77 19.9391 88.6121 36.9391 104.5C53.9391 120.388 75.8251 130 92.4391 130C109.053 130 130.939 120.388 147.939 104.5C164.939 88.6121 175.439 77 172.336 10C172.336 10 155.336 39 139.836 74C124.336 109 106.336 107 102.836 95.5C102.286 93.6924 101.819 92.0454 101.395 90.5532C99.1225 82.5517 98.1139 79 92.4391 79C86.7643 79 85.7557 82.5517 83.4834 90.5532C83.0596 92.0454 82.5919 93.6924 82.0418 95.5C78.5418 107 60.5418 109 45.0418 74C29.5418 39 12.5418 10 12.5418 10Z"/>
      <path id="beard-4" d="M11.8906 10C10.4274 22.3333 6.83776 63.0469 16.3688 86C27.7878 113.5 61.0057 130 91.7878 130C122.57 130 155.788 113.5 167.207 86C176.738 63.0469 173.148 22.3333 171.685 10C171.685 10 154.685 39 139.185 74C123.685 109 105.685 107 102.185 95.5C101.635 93.6924 101.167 92.0454 100.743 90.5532C98.4712 82.5517 97.4626 79 91.7878 79C86.113 79 85.1044 82.5517 82.8321 90.5532C82.4084 92.0454 81.9406 93.6924 81.3905 95.5C77.8906 107 59.8906 109 44.3905 74C28.8905 39 11.8906 10 11.8906 10Z"/>
      <path id="beard-5" d="M49.9099 121C57.1279 126 70.9098 130 91.9099 130C112.91 130 126.692 126 133.91 121C141.128 116 160.646 98.2033 167.91 76C174.011 57.3528 173.012 37.0947 172.238 21.4078C172.033 17.2448 171.844 13.4036 171.807 10C171.807 10 154.807 39 139.307 74C123.807 109 105.807 107 102.307 95.5C101.757 93.6924 101.289 92.0455 100.866 90.5532C98.5933 82.5517 97.5847 79 91.9099 79C86.2351 79 85.2265 82.5517 82.9542 90.5532C82.5305 92.0455 82.0628 93.6924 81.5126 95.5C78.0127 107 60.0127 109 44.5126 74C29.0126 39 12.0127 10 12.0127 10C11.9761 13.4036 11.7866 17.2448 11.5814 21.4078C10.8078 37.0947 9.80885 57.3528 15.9098 76C23.1743 98.2033 42.6919 116 49.9099 121Z"/>
      <path id="beard-6" d="M12.2803 10C11.2803 70 11.4242 90.9644 40.1776 107C47.038 110.826 52.5756 114.234 57.3598 117.179C70.7071 125.394 78.1909 130 92.1775 130C106.164 130 113.648 125.394 126.995 117.179C131.779 114.234 137.317 110.826 144.177 107C172.931 90.9644 173.075 70 172.075 10C171.132 15.5239 165.309 23.5569 158.646 30.4618C153.68 35.6071 148.247 41.2365 144.018 47.5C133.783 62.6593 128.731 76.0248 125.453 84.6965C124.5 87.2184 123.696 89.3434 122.959 91C119.688 98.3529 108.244 99.0621 105.791 91C103.339 82.9379 100.911 79 92.1775 79C83.4444 79 81.0161 82.9379 78.5635 91C76.111 99.0621 64.6667 98.3529 61.3955 91C60.6585 89.3434 59.8553 87.2184 58.902 84.6964C55.6241 76.0248 50.572 62.6593 40.3367 47.5C36.1077 41.2365 30.6749 35.6071 25.7092 30.4618C19.0456 23.5569 13.2232 15.5239 12.2803 10Z"/>
      <path id="beard-7" d="M12.1028 10C10.6396 22.3333 6.80224 65 15.581 81C23.2452 94.9687 38.3329 107.084 62.218 122.771C71.6447 128.962 80.4178 130 92 130C103.582 130 112.355 128.962 121.782 122.771C145.667 107.084 160.755 94.9687 168.419 81C177.198 65 173.36 22.3333 171.897 10C170.954 15.5239 165.132 23.5569 158.468 30.4617C153.503 35.6071 148.07 41.2365 143.841 47.5C133.606 62.6593 128.553 76.0248 125.276 84.6964C124.322 87.2184 123.519 89.3434 122.782 91C119.511 98.3529 108.067 99.0621 105.614 91C103.161 82.9379 100.733 79 92 79C83.2669 79 80.8386 82.9379 78.386 91C75.9335 99.0621 64.4892 98.3529 61.218 91C60.4809 89.3434 59.6777 87.2184 58.7244 84.6965C55.4465 76.0248 50.3944 62.6593 40.1591 47.5C35.9301 41.2365 30.4973 35.6071 25.5317 30.4618C18.868 23.5569 13.0457 15.5239 12.1028 10Z"/>
      <path id="beard-8" d="M12.5418 10C9.44795 76.8082 19.8791 88.5566 36.7933 104.364L36.9391 104.5C53.9391 120.388 75.8251 130 92.4391 130C109.053 130 130.939 120.388 147.939 104.5L148.085 104.364C164.999 88.5566 175.43 76.8082 172.336 10C171.393 15.5239 165.571 23.5569 158.907 30.4618C153.942 35.6071 148.509 41.2365 144.28 47.5C134.045 62.6593 128.993 76.0248 125.715 84.6964L125.715 84.6967C124.761 87.2186 123.958 89.3434 123.221 91C119.95 98.3529 108.506 99.0621 106.053 91C103.6 82.9379 101.172 79 92.4391 79C83.706 79 81.2777 82.9379 78.8251 91C76.3725 99.0621 64.9282 98.3529 61.657 91C60.92 89.3434 60.1168 87.2186 59.1636 84.6967L59.1635 84.6964C55.8856 76.0248 50.8335 62.6593 40.5982 47.5C36.3692 41.2365 30.9364 35.6071 25.9708 30.4618L25.9708 30.4618C19.3071 23.5569 13.4847 15.5239 12.5418 10Z"/>
      <path id="beard-9" d="M11.8906 10C10.4274 22.3333 6.83776 63.0469 16.3688 86C27.7878 113.5 61.0057 130 91.7878 130C122.57 130 155.788 113.5 167.207 86C176.738 63.0469 173.148 22.3333 171.685 10C170.742 15.5239 164.92 23.5569 158.256 30.4618C153.29 35.6071 147.858 41.2365 143.629 47.5C133.393 62.6593 128.341 76.0248 125.063 84.6965C124.11 87.2184 123.307 89.3434 122.57 91C119.299 98.3529 107.854 99.0621 105.402 91C102.949 82.9379 100.521 79 91.7878 79C83.0547 79 80.6264 82.9379 78.1738 91C75.7213 99.0621 64.277 98.3529 61.0057 91C60.2687 89.3434 59.4655 87.2184 58.5122 84.6964C55.2343 76.0248 50.1822 62.6593 39.9469 47.5C35.7179 41.2365 30.2851 35.6071 25.3195 30.4618C18.6558 23.5569 12.8335 15.5239 11.8906 10Z"/>
      <path id="beard-10" d="M91.9099 79C83.1768 79 80.7486 82.9379 78.296 91C75.8434 99.0621 64.3991 98.3529 61.1279 91C60.3909 89.3434 59.5876 87.2184 58.6343 84.6964C55.3565 76.0248 50.3044 62.6593 40.0691 47.5C35.8401 41.2365 30.4073 35.6071 25.4416 30.4618C18.778 23.5569 12.9556 15.5239 12.0127 10C11.9755 13.4563 11.7808 19.2729 11.5718 23.3676C10.7997 38.4998 9.83388 57.4293 15.9098 76C23.1743 98.2033 42.6919 116 49.9099 121C57.1279 126 70.9098 130 91.9099 130C112.91 130 126.692 126 133.91 121C141.128 116 160.645 98.2033 167.91 76C173.986 57.4293 173.02 38.4998 172.248 23.3677C172.039 19.2729 171.844 13.4563 171.807 10C170.864 15.5239 165.042 23.5569 158.378 30.4618C153.412 35.6071 147.98 41.2365 143.751 47.5C133.515 62.6593 128.463 76.0248 125.185 84.6965C124.232 87.2184 123.429 89.3434 122.692 91C119.421 98.3529 107.976 99.0621 105.524 91C103.071 82.9379 100.643 79 91.9099 79Z"/>
      <path id="beard-11" d="M40.1776 107C11.4242 90.9644 11.2803 70 12.2803 10C34.4878 26.3389 49.1775 45 52.1775 62C55.1775 79 65.6775 94 84.6775 81.5C88.3195 79.104 92.1775 79 92.1775 79C92.1775 79 96.0355 79.104 99.6774 81.5C118.677 94 129.177 79 132.177 62C135.177 45 149.867 26.3389 172.075 10C173.075 70 172.931 90.9644 144.177 107C118.177 121.5 111.177 130 92.1775 130C73.1776 130 66.1776 121.5 40.1776 107Z"/>
      <path id="beard-12" d="M12.1028 10C10.6396 22.3333 6.80224 65 15.581 81C23.2452 94.9687 38.3329 107.084 62.218 122.771C71.6447 128.962 80.4178 130 92 130C103.582 130 112.355 128.962 121.782 122.771C145.667 107.084 160.755 94.9687 168.419 81C177.198 65 173.36 22.3333 171.897 10C149.69 26.3389 135 45 132 62C129 79 118.5 94 99.5 81.5C95.858 79.104 92 79 92 79C92 79 88.142 79.104 84.5 81.5C65.5 94 55 79 52 62C49 45 34.3103 26.3389 12.1028 10Z"/>
      <path id="beard-13" d="M12.5418 10C9.43907 77 19.9391 88.6121 36.9391 104.5C53.9391 120.388 75.8251 130 92.4391 130C109.053 130 130.939 120.388 147.939 104.5C164.939 88.6121 175.439 77 172.336 10C150.129 26.3389 135.439 45 132.439 62C129.439 79 118.939 94 99.9391 81.5C96.2971 79.104 92.4391 79 92.4391 79C92.4391 79 88.5811 79.104 84.9391 81.5C65.9391 94 55.4391 79 52.4391 62C49.4391 45 34.7493 26.3389 12.5418 10Z"/>
      <path id="beard-14" d="M11.8906 10C10.4274 22.3333 6.83776 63.0469 16.3688 86C27.7878 113.5 61.0057 130 91.7878 130C122.57 130 155.788 113.5 167.207 86C176.738 63.0469 173.148 22.3333 171.685 10C149.477 26.3389 134.788 45 131.788 62C128.788 79 118.288 94 99.2878 81.5C95.6458 79.104 91.7878 79 91.7878 79C91.7878 79 87.9297 79.104 84.2878 81.5C65.2878 94 54.7878 79 51.7878 62C48.7878 45 34.0981 26.3389 11.8906 10Z"/>
      <path id="beard-15" d="M91.9099 130C70.9098 130 57.1279 126 49.9099 121C42.6919 116 23.1743 98.2033 15.9098 76C8.18974 52.4041 11.8381 26.2289 12.0127 10C34.2202 26.3389 48.9099 45 51.9099 62C54.9099 79 65.4099 94 84.4099 81.5C88.0519 79.104 91.9099 79 91.9099 79C91.9099 79 95.768 79.104 99.4099 81.5C118.41 94 128.91 79 131.91 62C134.91 45 149.6 26.3389 171.807 10C171.982 26.2289 175.63 52.4041 167.91 76C160.646 98.2033 141.128 116 133.91 121C126.692 126 112.91 130 91.9099 130Z"/>

      {/* NOSE SEPTUM NEW */}
      <path id="septum-1" d="M21.9453 12.0469C25.1109 21.4482 16.6994 26.5098 14.6172 34.4876C13.4434 38.9845 14.6172 51.8281 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-2" d="M20.8984 12.0469C24.0641 21.4482 14.8493 24.2189 14.6172 32.4475C14.3991 40.1811 17.2927 44.1208 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-3" d="M24.0391 12.0469C24.0391 12.0469 17.7578 15.617 18.8047 31.4275C19.8516 47.238 13.5703 44.5 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-4" d="M15.6241 12.0469C18.9137 30.6475 13.3778 36.2276 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-5" d="M20.8984 12.0469C24.0391 21.2272 21.9453 30.4075 19.8516 34.4876C17.7578 38.5677 12.1146 43.6679 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-6" d="M19.8516 12.0469C20.9569 33.4675 14.972 34.6854 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-7" d="M19.8516 12.0469C20.3513 22.9044 13.1179 30.1132 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-8" d="M19.8516 12.0469C14.4943 25.6479 11.4924 41.0041 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-9" d="M16.7109 12.0469C16.7109 12.0469 11.4766 44.1779 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-10" d="M14.0938 51.8281C12.8088 42.6532 14.7634 37.3125 17.5486 27.9594C20.3338 18.6062 16.1139 13.5804 12 12.0469" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-11" d="M25.0859 11V38.2188" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-12" d="M25.0859 25.6562V43.4531" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="septum-13" d="M21.9453 12.0469C12.5234 31.3525 17.2344 27.2574 14.6172 51.8281" stroke-linecap="round" stroke-linejoin="round"/>


      {/* NOSE NOSTRILS NEW */}
      <path id="nostrils-1" d="M7 46.0625C7.60655 47.1272 5.73732 49.8262 14.3281 52.3438C17.9244 55.0412 21.6002 57.5305 24.6486 57.5781C27.697 57.5305 31.3728 55.0412 34.9691 52.3438C43.5599 49.8262 41.6907 47.1272 42.2972 46.0625" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-2" d="M9 50.2499C13.7109 49.7265 16.5677 57.4886 24.5549 57.5781C32.542 57.4886 35.3988 49.7265 40.1097 50.2499" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-3" d="M42.7344 41.875C46.9219 41.875 46.9219 46.9602 42.7344 48.8727C38.5469 50.7852 38.3866 52.0092 35.6365 53.8248C33.1578 55.4612 30.0094 56.6097 24.9375 56.6097C19.8656 56.6097 16.7172 55.4613 14.2385 53.8249C11.4884 52.0093 11.3281 50.7851 7.14062 48.8727C2.95312 46.9602 2.95312 41.875 7.14062 41.875" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-4" d="M4 48.1562C4 48.1562 10.008 48.1563 14.6535 52.5847C19.299 57.0131 21.5804 58.5994 24.9375 58.6249C28.2946 58.5994 30.576 57.0132 35.2215 52.5848C39.867 48.1564 45.875 48.1563 45.875 48.1563" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-5" d="M4 46.0625C7.45726 46.9638 9.40487 48.0412 12.0055 50.491C16.9532 55.1518 20.8982 56.5312 24.9375 56.5312C28.9768 56.5312 32.7816 54.8614 37.8695 50.491C40.4563 48.269 42.4863 47.3847 45.875 47.0037" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-6" d="M11.2554 53.3954C14.2477 51.4684 15.5202 53.2056 16.3415 54.4375C18.4352 57.5781 20.9042 60.7187 24.7165 60.7187C28.5288 60.7187 30.9977 57.5781 33.0915 54.4375C33.9127 53.2056 35.1852 51.4684 38.1776 53.3954M9.47241 45.0156C9.47241 45.0156 2.99575 52.6629 7.73297 56.0078M39.9605 45.0156C39.9605 45.0156 46.4372 52.6629 41.7 56.0078" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-7" d="M11.2554 54.4423C11.2554 54.4423 11.6305 52.5152 13.6599 52.5152C16.0733 52.5152 17.5172 53.935 19.2035 56.7543C20.8897 59.5736 22.7616 60.7187 24.8647 60.7187C26.9679 60.7187 28.8397 59.5736 30.526 56.7543C32.2122 53.935 33.6562 52.5152 36.0695 52.5152C38.0989 52.5152 38.4741 54.4423 38.4741 54.4423M9.47241 46.0625C9.47241 46.0625 2.99575 53.7097 7.73297 57.0547M40.257 46.0625C40.257 46.0625 46.7337 53.7097 41.9965 57.0547" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-8" d="M11.1604 56.5312C3.95917 56.2435 3.82948 47.0937 7.96448 46.0625M10.4531 52.8672C18.6356 49.7266 17.7423 58.625 25.1094 58.625C32.4765 58.625 31.5831 49.7266 39.7656 52.8672M42.2543 46.0625C46.3893 47.0937 46.2596 56.2435 39.0583 56.5312" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-9" d="M8.30552 53.3922C3.65722 51.2091 6.06943 45.0156 6.06943 45.0156M11.7284 51.4165C17.0238 50.9987 21.1358 59.6719 25.7219 59.6719C30.3079 59.6719 33.3731 52.0455 39.7154 51.4165M45.3743 45.0156C45.3743 45.0156 47.7865 51.2091 43.1382 53.3922" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-10" d="M7.64604 51.8204C3.08657 49.6372 5.45268 43.4438 5.45268 43.4438M11.3101 49.7265C15.4774 51.4025 16.3136 57.5781 24.9195 57.5781C33.5254 57.5781 34.3615 51.4025 38.5289 49.7265M44.3863 43.4437C44.3863 43.4437 46.7524 49.6371 42.1929 51.8203" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-11" d="M5.76516 52.8672C2.07482 51.616 5.76516 45.0156 5.76516 45.0156M9.35938 51.2969C17.4065 51.2969 19.6511 57.5784 25.0631 57.5781C30.4751 57.5779 32.7185 51.2969 40.7656 51.2969M44.3598 52.8672C48.0502 51.616 44.3598 45.0156 44.3598 45.0156" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-12" d="M5.76515 51.2969C2.07481 49.9623 5.76515 42.9219 5.76515 42.9219M9.35938 49.2031C14.2658 49.2031 15.9328 58.625 25.0631 58.625C34.1934 58.625 35.8592 49.2031 40.7656 49.2031M44.3598 51.2969C48.0502 49.9623 44.3598 42.9219 44.3598 42.9219" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-13" d="M4.55987 42.9219C1.80678 50.6333 9.86045 49.1867 14.5052 52.3438C19.1499 55.5008 19.1593 59.6719 24.9739 59.6719C30.7885 59.6719 30.798 55.5008 35.4427 52.3438C40.0874 49.1867 48.1411 50.6333 45.388 42.9219" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-14" d="M4.97082 39.7812C2.2572 44.7437 5.62709 45.2675 10.2052 48.1562C14.7833 51.045 16.6935 58.625 24.8615 58.625C33.0294 58.625 34.9396 51.045 39.5177 48.1562C44.0958 45.2675 47.4657 44.7437 44.7521 39.7812" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-15" d="M42.7344 45.0156C45.5593 45.0156 47.1316 50.0335 44.3856 52.1621C41.6397 54.2907 36.9805 51.4626 33.3125 52.8672C29.0452 54.5012 28.4329 57.5781 24.8781 57.5781C21.3233 57.5781 20.7704 54.5012 16.5031 52.8672C12.8351 51.4626 8.17595 54.2907 5.43001 52.1621C2.68405 50.0335 4.25632 45.0156 7.08124 45.0156" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-16" d="M6.89071 43.9688C4.09808 44.8868 3.12111 49.1928 4.88506 51.0212C7.83318 54.077 9.7394 50.8105 13.4928 52.1966C17.8594 53.8091 20.1097 57.5781 24.9375 57.5781C29.7653 57.5781 32.0156 53.8091 36.3822 52.1966C40.1356 50.8105 42.0418 54.077 44.9899 51.0212C46.7539 49.1928 45.7769 44.8868 42.9843 43.9688" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-17" d="M7.42618 42.922C4.60126 42.922 2.72018 47.9399 5.46614 50.0684C8.21208 52.197 11.0105 50.6263 13.3986 51.8204C15.4924 52.8673 19.065 59.672 25.0625 59.672C31.06 59.672 34.6326 52.8672 36.7264 51.8203C39.1145 50.6263 41.9129 52.1969 44.6589 50.0684C47.4048 47.9398 45.5238 42.9219 42.6988 42.9219" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-18" d="M7.72266 41.3516C4.89774 41.3516 3.01666 46.3695 5.76262 48.4981C8.50856 50.6266 10.2601 49.0559 12.6482 50.25C14.742 51.2969 16.0726 59.672 25.2108 59.672C34.3489 59.672 35.6795 51.2969 37.7732 50.25C40.1614 49.0559 41.9129 50.6266 44.6589 48.4981C47.4048 46.3695 45.5237 41.3516 42.6988 41.3516" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-19" d="M43.9051 43.9688C47.2374 49.2031 42.2585 51.8203 38.3861 52.3438C34.5137 52.8672 31.591 56.5312 25.1094 56.5312C18.6277 56.5312 15.705 52.8672 11.8326 52.3438C7.96021 51.8203 2.98139 49.2031 6.31361 43.9688" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-20" d="M7.72272 45.0156C4.8978 45.0156 3.01673 50.0335 5.76268 52.1621C8.50862 54.2907 14.6429 53.0623 14.6429 53.0623C14.6429 53.0623 18.5153 58.625 25.2108 58.625C31.9062 58.625 35.7786 53.0623 35.7786 53.0623C35.7786 53.0623 41.913 54.2907 44.6589 52.1621C47.4049 50.0335 45.5238 45.0156 42.6989 45.0156" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-21" d="M7.72271 43.9688C4.89779 43.9688 3.01672 48.9866 5.76267 51.1152C8.50861 53.2438 14.742 52.3438 14.742 52.3438C14.742 52.3438 15.3747 60.7188 25.2108 60.7188C35.0469 60.7188 35.6795 52.3438 35.6795 52.3438C35.6795 52.3438 41.9129 53.2438 44.6589 51.1152C47.4048 48.9866 45.5238 43.9688 42.6988 43.9688" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-22" d="M4.22986 43.9688C2.34543 53.1592 12.6049 48.1562 14.6986 52.3438C16.7924 56.5312 20.6508 57.5781 25.1674 57.5781C29.684 57.5781 33.5424 56.5312 35.6361 52.3438C37.7299 48.1562 47.9893 53.1592 46.1049 43.9688" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-23" d="M6.85157 45.7611C11.9015 46.7711 12.6525 52.3906 16.4464 53.841C17.9862 54.4297 20.8116 54.851 25.0312 54.851C29.2509 54.851 32.0763 54.4297 33.6161 53.841C37.41 52.3906 38.161 46.7711 43.2109 45.7611M7.35656 38.7283C0.890169 37.2665 1.03611 46.3029 3.82163 48.8282M42.7059 38.7283C49.1723 37.2665 49.0264 46.3029 46.2409 48.8282" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="nostrils-24" d="M7.2048 39.7813C2.49387 40.3048 3.0173 48.1563 3.0173 48.1563M8.25167 48.6905C10.581 49.2175 12.3843 50.2643 14.5156 52.3438C16.1551 53.9434 18.76 55.4844 24.993 55.4844C31.2261 55.4844 33.8137 53.9434 35.4531 52.3438C37.5845 50.2643 39.3877 49.2174 41.7171 48.6904M42.764 39.7812C47.4749 40.3047 46.9514 48.1562 46.9514 48.1562" stroke-linecap="round" stroke-linejoin="round"/>


      {/* EYES WHITE NEW */}
      <path id="white-1" d="M46.9999 23C46.9999 23 37.6619 16.208 25 16C12.3381 16.208 6.99988 23 6.99988 23C6.99988 23 9.99987 31 24.9999 30C34.9779 29.3348 46.9999 23 46.9999 23Z" fill="white"/>
      <path id="white-2" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 13.7249 30 24 30C34.2751 30 47 23 47 23Z" fill="white"/>
      <path id="white-3" d="M6.99976 23C6.99976 23 12.3381 16.208 25 16C37.6619 16.208 46.9998 23 46.9998 23C46.9998 23 42.9998 31 27.9998 30C18.0218 29.3348 6.99976 23 6.99976 23Z" fill="white"/>
      <path id="white-4" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 15.7249 30 28 30C46.2751 30 47 23 47 23Z" fill="white"/>
      <path id="white-5" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 18.7249 30 29 30C39.2751 30 47 23 47 23Z" fill="white"/>
      <path id="white-6" d="M46.9999 23C46.9999 23 37.6619 16.208 25 16C12.3381 16.208 6.99995 23 6.99995 23C6.99995 23 6.72489 30 26.9999 30C47.275 30 46.9999 23 46.9999 23Z" fill="white"/>
      <path id="white-7" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 11.7249 30 27 30C42.2751 30 47 23 47 23Z" fill="white"/>
      <path id="white-8" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 16.7249 30 27 30C37.2751 30 47 23 47 23Z" fill="white"/>
      <path id="white-9" d="M46.9999 23C46.9999 23 37.6619 16.208 25 16C12.3381 16.208 6.99988 23 6.99988 23C6.99988 23 9.99987 32 24.9999 31C34.9779 30.3348 46.9999 23 46.9999 23Z" fill="white"/>
      <path id="white-10" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 13.7249 31 24 31C34.2751 31 47 23 47 23Z" fill="white"/>
      <path id="white-11" d="M6.99976 23C6.99976 23 12.3381 16.208 25 16C37.6619 16.208 46.9998 23 46.9998 23C46.9998 23 42.9998 32 27.9998 31C18.0218 30.3348 6.99976 23 6.99976 23Z" fill="white"/>
      <path id="white-12" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 15.7249 31 28 31C46.2751 31 47 23 47 23Z" fill="white"/>
      <path id="white-13" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 18.7249 31 29 31C39.2751 31 47 23 47 23Z" fill="white"/>
      <path id="white-14" d="M46.9999 23C46.9999 23 37.6619 16.208 25 16C12.3381 16.208 6.99995 23 6.99995 23C6.99995 23 6.72489 31 26.9999 31C47.275 31 46.9999 23 46.9999 23Z" fill="white"/>
      <path id="white-15" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 11.7249 31 27 31C42.2751 31 47 23 47 23Z" fill="white"/>
      <path id="white-16" d="M47 23C47 23 37.6619 16.208 25 16C12.3381 16.208 7 23 7 23C7 23 16.7249 31 27 31C37.2751 31 47 23 47 23Z" fill="white"/>

      {/* EYES IRIS NEW */}
      <path id="iris-1" d="M19 22C19 26.4183 22.5817 30 27 30C31.4183 30 35 26.4183 35 22C35 17.5817 31.4183 14 27 14C22.5817 14 19 17.5817 19 22Z"/>
      <path id="iris-2" d="M21 22C21 25.3137 23.6863 28 27 28C30.3137 28 33 25.3137 33 22C33 18.6863 30.3137 16 27 16C23.6863 16 21 18.6863 21 22Z"/>
      <path id="iris-3" d="M20 22C20 25.866 23.134 29 27 29C30.866 29 34 25.866 34 22C34 18.134 30.866 15 27 15C23.134 15 20 18.134 20 22Z"/>

      {/* EYES PUPIL NEW */}
      <circle id="pupil-1" r="4" transform="matrix(-1 5.18986e-09 5.18986e-09 1 27 22)" fill="black"/>
      <circle id="pupil-2" r="2" transform="matrix(-1 0 0 1 27 22)" fill="black"/>
      
      {/* EYES EYELID NEW */}
      <path id="eyelid-1" d="M26 17C13.3381 17.208 7 23 7 23C7 18 16 13 26 13C36 13 47 20 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-2" d="M26 17C13.3381 17.208 7 23 7 23C7 20 17 13 27 13C37 13 47 20 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-3" d="M26 17C13.3381 17.208 7 23 7 23C7 18 17 13 27 13C37 13 47 18 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-4" d="M26 17C38.6619 17.208 47 23 47 23C47 18 38 13 28 13C18 13 7 20 7 23C7 23 13.3381 17.208 26 17Z"/>
      <path id="eyelid-5" d="M26 17C13.3381 17.208 7 23 7 23C7 16 15 10 26 10C37 10 47 20 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-6" d="M26 17C13.3381 17.208 7 23 7 23C7 20 16 10 27 10C38 10 47 20 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-7" d="M26 17C13.3381 17.208 7 23 7 23C7 16 16 10 27 10C38 10 47 16 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-8" d="M26 17C38.6619 17.208 47 23 47 23C47 16 39 10 28 10C17 10 7 20 7 23C7 23 13.3381 17.208 26 17Z"/>
      <path id="eyelid-9" d="M26 17C13.3381 17.208 7 23 7 23C7 14 14 7 26 7C38 7 47 20 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-10" d="M26 17C13.3381 17.208 7 23 7 23C7 20 15 7 27 7C39 7 47 20 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-11" d="M26 17C13.3381 17.208 7 23 7 23C7 14 15 7 27 7C39 7 47 14 47 23C47 23 38.6619 17.208 26 17Z"/>
      <path id="eyelid-12" d="M26 17C38.6619 17.208 47 23 47 23C47 14 40 7 28 7C16 7 7 20 7 23C7 23 13.3381 17.208 26 17Z"/>

      {/* EYES EYELASHES NEW */}
      <path id="eyelashes-1" d="M7 23C7 23 10 18 23 18C36 18 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-2" d="M7 23C7 23 11 18 25 18C39 18 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-3" d="M7 23C7 23 13 18 27 18C41 18 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-4" d="M7 23C7 23 15 18 29 18C43 18 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-5" d="M7 23C7 23 10 17 23 17C36 17 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-6" d="M7 23C7 23 15.2439 17 25 17C34.7561 17 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-7" d="M7 23C7 23 17.2439 17 27 17C36.7561 17 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-8" d="M7 23C7 23 19.2439 17 29 17C38.7561 17 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-9" d="M7 23C7 23 12.2683 16 23 16C36.6585 16 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-10" d="M7 23C7 23 11.3415 16 25 16C38.6585 16 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-11" d="M7 23C7 23 13.3415 16 27 16C40.6585 16 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="eyelashes-12" d="M7 23C7 23 15.3415 16 29 16C42.6585 16 47 23 47 23" stroke-linecap="round" stroke-linejoin="round"/>

      {/* EYEBROWS NEW */}
      <path id="eyebrows-1" d="M16.6997 18.3861C20.5402 16.3678 25.949 14.3651 32.2796 14.3651C47.1197 14.3651 58.0926 21.2291 61.6986 23.8168C62.8258 24.6258 64.4309 24.5772 65.4675 23.5602C66.5239 22.5236 66.6343 20.757 65.4054 19.698C62.0107 16.7725 52.0739 9.87222 34.0788 10.0018C26.5544 10.056 20.3515 12.8024 16.0483 15.5124C13.5913 17.0597 11.2202 18.8079 9.25967 20.9644C8.90671 21.3541 8.92033 21.9702 9.2919 22.3428C9.71928 22.7714 10.3343 22.6685 10.7579 22.3025C12.5549 20.7598 14.61 19.4843 16.6997 18.3861Z"/>
      <path id="eyebrows-2" d="M9.57404 20.9298C9.10512 21.1508 8.88241 21.6938 9.06163 22.1802C9.24085 22.6667 9.76232 22.9354 10.2626 22.7994C12.7565 22.1322 15.2808 21.5698 17.8124 21.0672C22.1969 20.1967 27.7108 19.3381 32.3626 19.3381C42.7019 19.3381 57.1783 22.482 61.9743 23.5952C63.3803 23.9216 64.8024 23.1356 65.274 21.7714C65.8238 20.181 64.8619 18.4478 63.1753 18.1345C58.1179 17.1949 44.4267 14.854 34.1609 15.0072C27.9247 15.1003 21.7768 16.5867 17.2103 18.0359C14.6251 18.8564 12.0316 19.7713 9.57404 20.9298Z"/>
      <path id="eyebrows-3" d="M12.0574 18.4048C18.468 14.3836 25.8625 9.74529 35.1516 11.0309C50.4836 13.153 60.2079 15.2537 64.1917 16.1928C65.8955 16.5944 66.5853 18.5712 65.5946 19.9624C65.0073 20.7872 63.978 21.1751 62.9867 20.9241C59.3514 20.0037 46.0555 16.6904 33.6317 14.2689C28.8411 13.3352 24.8908 14.3606 21.2138 16.046C19.377 16.888 17.6231 17.8877 15.8526 18.8967L15.8104 18.9208C14.0399 19.9299 12.2473 20.9501 10.4075 21.7707C9.92525 21.9858 9.3589 21.7889 9.11411 21.321C8.86931 20.8532 9.03043 20.2756 9.48207 20.0021C10.3236 19.4923 11.1814 18.9543 12.0574 18.4048Z"/>
      <path id="eyebrows-4" d="M10.4892 24.2317C10.0496 24.546 9.43984 24.4541 9.11231 24.0243C8.78477 23.5945 9.27789 22.6415 9.27789 22.6415C9.27789 22.6415 14.6552 18.51 17.4684 16.6307C20.8431 14.3764 25.9356 10.4713 30.2851 11.0374C46.8939 13.1991 60.0294 18.1095 64.584 19.9706C66.1009 20.5904 66.5371 22.4445 65.5833 23.7041C64.9146 24.5872 63.7474 24.9299 62.7047 24.53C58.4359 22.8929 42.031 16.7662 29.5796 14.3531C29.0784 14.2559 28.682 14.2204 28.3237 14.283C24.4983 14.952 20.7617 17.5551 17.5284 19.5624C15.1376 21.0468 12.7802 22.5964 10.4892 24.2317Z"/>
      <path id="eyebrows-5" d="M9.40917 19.9758C8.97923 20.291 8.87165 20.8884 9.1652 21.3333C9.46497 21.7877 10.075 21.8976 10.5343 21.6286C12.4912 20.4926 14.5318 19.4895 16.6006 18.5763C20.4139 16.8931 24.5136 15.3946 28.7048 15.1015C34.9636 14.599 40.8064 16.5117 46.6022 18.6211C52.219 20.6655 58.1761 22.8339 65.8113 22.6232C67.276 22.5828 68.3298 21.2939 68.1922 19.8902C68.0626 18.5672 66.9435 17.6056 65.6618 17.5848C59.8199 17.49 53.7275 16.0071 47.7238 14.5458C41.9423 13.1391 35.5469 11.0969 29.5226 11.8532C24.0451 12.541 19.0182 14.5774 15.3801 16.4234C13.3247 17.4663 11.2739 18.6119 9.40917 19.9758Z"/>
      <path id="eyebrows-6" d="M7.47833 20.3691C6.78941 21.1755 6.84829 22.3454 7.58747 23.0829C8.31752 23.8112 9.51675 23.8555 10.3004 23.1865C16.1148 18.2076 24.5412 15.5013 32.0753 15.5013C46.4429 15.5013 57.2586 21.5289 61.4345 24.2755C63.3667 25.5464 66.1081 25.1972 67.5412 23.1868C68.7087 21.5489 68.6362 19.1362 66.8708 17.7018C63.1111 14.6469 52.6331 7.87345 33.9325 8.0018C25.8757 8.0571 19.2606 11.1704 14.7062 14.2096C12.0687 15.9696 9.5463 17.9503 7.47833 20.3691Z"/>
      <path id="eyebrows-7" d="M8.05376 20.0961C10.6277 18.7192 13.366 17.6076 16.1069 16.6183C20.8932 14.8906 27.3928 13.1008 34.0267 13.0071C44.5124 12.8592 58.3651 15.0587 63.8803 16.0173C66.3921 16.4539 67.9306 18.9548 67.2666 21.3758C66.6547 23.6069 64.4063 24.9578 62.1551 24.4701C56.7143 23.2915 42.4284 20.4326 32.1758 20.4326C24.6232 20.4326 16.9483 21.9259 9.63871 23.7596C8.61847 24.0176 7.54005 23.6173 7.13712 22.5859C6.76911 21.644 7.16303 20.5751 8.05376 20.0961Z"/>
      <path id="eyebrows-8" d="M19.91 14.7165C24.3521 12.5104 29.3906 10.9435 35.1672 11.6994C50.6147 13.7207 60.583 15.7015 64.946 16.6502C67.5335 17.2128 68.8013 20.0401 67.6477 22.3433C66.8064 24.023 64.9224 24.9007 63.0973 24.4704C58.6572 23.4235 45.5702 20.3967 33.3112 18.1378C28.5769 17.2654 24.6715 18.2053 20.9738 19.798C17.1381 21.4501 13.6264 23.7613 9.77707 25.3845C8.80256 25.7954 7.67658 25.3766 7.20749 24.4287C6.73839 23.4808 7.08835 22.3316 8.00613 21.806C12.0166 19.5095 15.7621 16.7764 19.91 14.7165Z"/>
      <path id="eyebrows-9" d="M7.40824 24.6378C6.76451 23.7916 6.89584 22.5893 7.70658 21.9014C12.5463 17.8037 17.7735 13.7731 23.4495 10.8908C24.6227 10.2951 25.7917 9.78711 26.8917 9.44975C27.9643 9.12078 29.1229 8.90578 30.2173 9.04125C47.1949 11.1429 60.6863 15.8653 65.6825 17.7889C67.9543 18.6636 68.8309 21.2894 67.7377 23.3542C66.7943 25.1361 64.6869 25.9575 62.7835 25.2689C57.582 23.3871 41.4463 17.743 29.1438 15.4847C27.3061 15.1474 25.0831 16.4267 23.518 17.1763C18.854 19.4102 14.3979 22.1799 10.1245 25.0808C9.24524 25.6786 8.05196 25.484 7.40824 24.6378Z"/>
      <path id="eyebrows-10" d="M7.61532 19.242C7.61532 19.242 7.61494 19.2432 7.61447 19.2427C6.81956 19.9033 6.66473 21.064 7.25773 21.917C7.84412 22.7605 9.00426 23.0252 9.89808 22.5203C11.9153 21.3938 14.0155 20.4038 16.1414 19.5026C19.8521 17.9295 24.4521 16.3669 28.4371 16.0993L28.4568 16.0979C35.09 15.5865 40.4407 17.3761 46.3052 19.4247L46.7431 19.5778C52.451 21.5736 58.6494 23.7409 66.5177 23.6327C68.906 23.5999 70.6185 21.5396 70.4545 19.2876C70.2925 17.0623 68.4286 15.4442 66.3209 15.3829C60.5019 15.2135 54.4415 13.7972 48.358 12.3755L47.4625 12.1664C41.2059 10.7072 34.8489 9.28133 29.2111 9.65995C23.9175 10.0155 18.5339 12.3954 13.9771 14.9797C11.7639 16.2349 9.5879 17.6273 7.61532 19.242Z"/>
      <path id="eyebrows-11" d="M6.86627 22.6395C6.86627 22.6395 7.2172 22.1064 6.86542 22.6408C6.51363 23.1753 6.49042 24.507 7.18669 25.1626C7.86853 25.8047 8.96209 25.8788 9.72322 25.3319C11.5832 24.0257 13.6485 23.0158 15.7235 22.1064C19.6213 20.3981 25.1969 18.5738 31.9039 18.1048C44.0446 17.2558 54.0201 20.6896 59.7309 23.3451C63.4279 25.0641 68.3519 23.3256 69.5274 19.0722C70.159 16.7868 69.4795 14.1358 67.2535 12.6848C62.6757 9.7009 51.5903 4.24987 33.3195 5.65194C24.9352 6.29535 18.3052 10.6131 13.8432 14.639C11.2745 16.9567 8.73695 19.6816 6.87012 22.6334C6.86862 22.6358 6.86627 22.6395 6.86627 22.6395Z"/>
      <path id="eyebrows-12" d="M7.27844 22.1693C6.58625 22.7454 6.36443 23.713 6.73659 24.5331C7.10875 25.3531 7.98344 25.8232 8.87278 25.6816C11.5107 25.2653 14.155 24.8879 16.8015 24.5311C21.4083 23.91 27.2431 23.2053 32.2361 22.8562C41.3632 22.218 53.7761 23.5968 60.6415 24.525C64.4344 25.0379 67.9861 22.3369 68.3809 18.4741C68.752 14.8442 66.155 11.5422 62.4606 11.1859C55.8575 10.549 43.2911 9.59064 33.6368 10.4045C26.728 10.987 20.1185 13.9754 15.3214 16.7273C12.778 18.1864 8.5 21.1526 7.27844 22.1693Z"/>
      <path id="eyebrows-13" d="M6.29315 24.5579C8.88565 21.5 12.9938 17.7672 16.443 15.4338C21.52 11.9992 28.1821 8.68095 34.3728 8.98455C49.0324 9.70348 59.1074 11.1014 64.3795 11.9955C67.8907 12.591 70.0271 15.9672 69.3367 19.3454C68.6343 22.7825 65.3041 24.9996 61.8748 24.3766C55.7392 23.2619 43.9637 21.2651 32.933 20.1946C22.5777 19.1897 16.7484 23.672 8.88565 27.3454C8.09927 27.7128 7.16568 27.531 6.57457 26.8955C5.98347 26.2599 5.86978 25.3156 6.29315 24.5579Z"/>
      <path id="eyebrows-14" d="M6.56078 24.1863L6.5735 24.1724C11.2795 19.0304 16.3887 13.8689 22.1803 9.93796C24.1738 8.58492 26.6306 7.03333 29.1434 7.15655C45.4555 7.95653 58.8822 11.172 65.1353 12.9134C68.3312 13.8034 70.1409 17.0046 69.4969 20.1557C68.7515 23.8031 65.079 26.0331 61.511 25.0961C54.2155 23.1802 39.7135 19.6264 28.4357 18.4407C22.2756 17.793 14.3059 24.1349 9.10176 27.2283L9.06672 27.2492C7.14529 28.4 5.05313 25.8419 6.56078 24.1863Z"/>
      <path id="eyebrows-15" d="M6.00002 22C6.4438 22.6174 7.5 22.9579 8.37287 22.668C14.6983 20.5676 21.3794 18.8366 28.0363 18.3711C41.4159 17.3718 53.2482 25.622 66.5045 25.7025C73.8918 25.7474 73.6708 13 66.2276 12.6345C53.7571 12.0222 41.3838 5.64308 28.8046 7.16865C20.2081 8.21119 7.0866 18.5433 6.27603 19.3844C5.46546 20.2255 5.59075 21.4306 6.00002 22Z"/>
      <path id="eyebrows-16" d="M20.5301 18.3861C23.7051 16.3678 28.1766 14.3651 33.4102 14.3651C45.6789 14.3651 53.0189 20.4123 56 23C57.5 24.3021 59.6176 24.6911 60.8473 23.5602C62 22.5 61.8119 20.757 60.7959 19.698C57.9895 16.7725 49.7746 9.87222 34.8977 10.0018C28.6771 10.056 23.5491 12.8024 19.9916 15.5124C17.9604 17.0597 16.0001 18.8079 14.3793 20.9644C14.0875 21.3541 14.0988 21.9702 14.4059 22.3428C14.7593 22.7714 15.2677 22.6685 15.6179 22.3025C17.1035 20.7598 18.8025 19.4843 20.5301 18.3861Z"/>
      <path id="eyebrows-17" d="M14.6392 20.9298C14.2516 21.1508 14.0674 21.6938 14.2156 22.1802C14.3638 22.6667 14.7949 22.9354 15.2084 22.7994C17.2702 22.1322 19.3571 21.5698 21.45 21.0672C25.0748 20.1967 29.6332 19.3381 33.4789 19.3381C42.0266 19.3381 51.5 22 56 23C57.1786 23.2619 60.0381 24.0428 60.6873 21.7714C61.3366 19.5 59.3537 18.3133 57.9594 18C53.7784 17.0604 43.4525 14.854 34.9656 15.0072C29.81 15.1003 24.7274 16.5867 20.9522 18.0359C18.815 18.8564 16.6709 19.7713 14.6392 20.9298Z"/>
      <path id="eyebrows-18" d="M16.6923 18.4048C21.992 14.3836 28.1052 9.74529 35.7846 11.0309C48.4599 13.153 55.7065 15.0609 59 16C60.4086 16.4017 62 17.5 61.5 19.5C61 21.5 58.3195 20.751 57.5 20.5C54.4946 19.5795 44.799 16.6904 34.5281 14.2689C30.5676 13.3352 27.3018 14.3606 24.262 16.046C22.7435 16.888 21.2935 17.8877 19.8298 18.8967L19.7949 18.9208C18.3312 19.9299 16.8492 20.9501 15.3282 21.7707C14.9296 21.9858 14.4614 21.7889 14.259 21.321C14.0566 20.8532 14.1898 20.2756 14.5632 20.0021C15.2589 19.4923 15.9681 18.9543 16.6923 18.4048Z"/>
      <path id="eyebrows-19" d="M15.3958 24.2317C14.3182 25.1635 13 24 14.3944 22.6415C18.3586 18.9572 25.7807 10.0958 31.7614 11.0374C45.4921 13.1991 56.3515 18.1095 60.1169 19.9706C62 21 62.2885 22.2403 61.5 23.5C60.9472 24.383 59.5 24.7959 57.5 24C55.5 23.2041 41.4719 16.7662 31.1782 14.3531C27.5391 13.5 18.9097 21.1978 15.3958 24.2317Z"/>
      <path id="eyebrows-20" d="M14.5029 19.9758C14.1475 20.291 14.0585 20.8884 14.3012 21.3333C14.549 21.7877 15.0534 21.8976 15.4331 21.6286C17.0509 20.4926 18.7379 19.4895 20.4482 18.5763C23.6007 16.8931 26.99 15.3946 30.455 15.1015C35.6292 14.599 40.4595 16.5117 45.2511 18.6211C49.8945 20.6655 54.8194 22.8339 61.1315 22.6232C62.3424 22.5828 63.2136 21.2939 63.0999 19.8902C62.9927 18.5672 62.0676 17.6056 61.0079 17.5848C56.1784 17.49 51.1417 16.0071 46.1783 14.5458C41.3986 13.1391 36.1114 11.0969 31.131 11.8532C26.6027 12.541 22.4469 14.5774 19.4391 16.4234C17.74 17.4663 16.0446 18.6119 14.5029 19.9758Z"/>
      <path id="eyebrows-21" d="M12.9066 20.3691C12.3371 21.1755 12.3858 22.3454 12.9969 23.0829C13.6004 23.8112 14.8522 23.169 15.5 22.5C20.3069 17.5211 27.0128 15.5013 33.2413 15.5013C45.1193 15.5013 53.0477 20.4402 56.5 23.1868C58.0974 24.4577 60.7284 24.8737 62.5616 23.1868C64.3948 21.5 62.9595 18.4344 61.5 17C58.3918 13.9452 50.2368 7.87346 34.7768 8.0018C28.116 8.0571 22.6473 11.1704 18.882 14.2096C16.7015 15.9696 14.6163 17.9503 12.9066 20.3691Z"/>
      <path id="eyebrows-22" d="M14 20.5C19.0375 15.4625 28.0251 13.1237 34.8547 13.0071C43.5234 12.8592 54.0703 15.0346 57.5351 16.0173C61 17 62.8836 18.9548 62.3347 21.3758C61.8288 23.6069 60 24.4897 56.1089 23.4701C52.2177 22.4505 41.8005 20.4326 33.3245 20.4326C27.0806 20.4326 21.0429 21.6185 15 23.4522C12.4563 24.2303 12.5 22 14 20.5Z"/>
      <path id="eyebrows-23" d="M23.1841 14.7165C26.8565 12.5104 31.0219 10.9435 35.7975 11.6994C48.5682 13.7207 55.8608 14.9373 59 16.5C62.1392 18.0627 63.6885 19.4196 62.6497 22.3433C61.6109 25.267 50 21 34.2631 18.1378C30.3491 17.2654 27.1205 18.2053 24.0635 19.798C19.178 22.3433 13.6462 26 12.6827 24.4287C11.7192 22.8574 20.8964 16.0907 23.1841 14.7165Z"/>
      <path id="eyebrows-24" d="M12.8487 24.6378C11.2446 22.7828 26.3934 8.24587 31.7053 9.04125C45.741 11.1429 55.8946 15.8653 60.025 17.7889C61.9031 18.6636 63.6279 21.2894 62.7241 23.3542C61.9442 25.1361 59.5736 25.3264 58 24.6378C53.6998 22.756 40.9886 17.743 30.8179 15.4847C26.7074 14.5721 14.5218 26.5725 12.8487 24.6378Z"/>
      <path id="eyebrows-25" d="M12.7243 21.917C14 23.6327 18.9875 20.057 20.0686 19.5026C23.1363 17.9295 26.9392 16.367 30.2336 16.0993L30.2499 16.0979C41.0857 15.0874 49.9408 23.7517 60.7156 23.6327C63.69 23.5999 65.1057 21.5396 64.9702 19.2876C64.8362 17.0623 63.2954 15.4442 61.5529 15.3829C50.2894 14.9863 42.0861 8.74912 30.8736 9.65995C26.4972 10.0155 22.0465 12.3954 18.2793 14.9798C16.5048 16.1971 11.4486 20.2013 12.7243 21.917Z"/>
      <path id="eyebrows-26" d="M12.4007 22.6395C12.4007 22.6395 12.6908 22.1064 12.4 22.6408C12.1092 23.1753 12.09 24.507 12.6656 25.1626C13.2293 25.8047 14.1333 25.8788 14.7626 25.3319C16.3003 24.0257 18.0077 23.0158 19.7231 22.1064C22.9455 20.3981 27.555 18.5738 33.0997 18.1048C43.1367 17.2558 50.2788 19.9854 55 22.6408C58.0563 24.3598 62.5 24.055 64 20.5C65.5 16.945 63.3403 13.4509 61.5 12C57.7154 9.01609 49.3748 4.24986 34.2701 5.65194C27.3385 6.29534 21.8574 10.6131 18.1687 14.639C16.045 16.9567 13.9472 19.6816 12.4039 22.6334C12.4026 22.6358 12.4007 22.6395 12.4007 22.6395Z"/>
      <path id="eyebrows-27" d="M12.7414 22.1693C12.1692 22.7454 11.9858 23.713 12.2935 24.5331C12.6012 25.3531 13.3243 25.8232 14.0595 25.6816C16.2403 25.2653 18.4264 24.8879 20.6143 24.5311C24.4228 23.91 29.2466 23.2053 33.3744 22.8562C40.9199 22.218 50.8643 23.4872 56 24C61.1357 24.5128 64.1736 22.3627 64.5 18.5C64.8067 14.8701 62.4157 12.5422 58.3615 11.1859C53.1495 9.44226 42.5137 9.59064 34.5324 10.4045C28.8208 10.987 23.3566 13.9754 19.3907 16.7273C17.288 18.1864 13.7513 21.1526 12.7414 22.1693Z"/>
      <path id="eyebrows-28" d="M11.9269 24.5579C14.0701 21.5 17.4664 17.7672 20.3179 15.4338C24.5151 11.9992 30.0228 8.68095 35.1408 8.98455C47.2602 9.70348 55.6414 10.8536 59.0707 12.1768C62.5 13.5 64.5708 16.1218 64 19.5C63.4193 22.9371 60.3351 24.623 56.5 24C52.6649 23.377 45.0697 21.2651 35.9505 20.1946C27.3895 19.1897 20.5704 23.672 14.0701 27.3454C13.42 27.7128 12.6482 27.531 12.1595 26.8955C11.6708 26.2599 11.5769 25.3156 11.9269 24.5579Z"/>
      <path id="eyebrows-29" d="M12.1481 24.1863L12.1586 24.1724C16.0492 19.0304 20.273 13.8689 25.061 9.93796C26.7091 8.58492 28.7402 7.03333 30.8175 7.15655C44.3031 7.95653 54.8304 10.7586 60 12.5C64.1785 13.9075 64.8215 18.3443 63.5 21C62.1785 23.6557 59 24.6118 55.5 23.5C49.4687 21.5841 41.5561 19.6264 32.2325 18.4407C27.1398 17.793 18.5511 24.1349 14.2488 27.2283L14.2198 27.2492C12.6313 28.4 10.5 26.5 12.1481 24.1863Z"/>
      <path id="eyebrows-30" d="M11.6845 22C12.0514 22.6174 12.9245 22.9579 13.6462 22.668C18.8755 20.5676 24.3989 18.8366 29.9023 18.3711C40.9634 17.3718 47.7453 25.622 58.7046 25.7025C66.5 25.7598 65.5 12.4454 58 12C47.6904 11.3877 40.9369 5.64308 30.5375 7.16864C23.4305 8.21119 12.5828 18.5433 11.9127 19.3844C11.2426 20.2255 11.3461 21.4306 11.6845 22Z"/>

      {/* GLASSES NEW */}
      <path id="glasses-1" d="M66.2052 62.6665C66.6031 62.6658 66.9976 62.6579 67.3882 62.6425C67.8527 62.6243 68.3087 62.5896 68.7564 62.5392C75.4716 61.7837 80.311 57.49 83.7833 51.9281C84.2484 51.1832 84.6853 50.4205 85.0954 49.6472C87.6993 44.7361 89.2184 39.3944 89.9543 35.445C86.1765 31.4872 80.2104 28.0599 73.0597 25.3843C65.4511 22.5373 56.7493 20.6336 48.5069 19.8099C46.0142 19.5608 43.58 19.4121 41.2446 19.3639C37.4796 19.4522 34.096 19.878 31.3165 20.6384C26.1244 22.0589 24.0962 24.2769 23.968 26.6122C23.4862 35.3907 28.9807 44.5718 37.6252 51.608C45.8917 58.3366 56.5499 62.6813 66.2052 62.6665ZM95 36C94.0601 40.9835 91.838 48.4679 88.0247 54.576C83.8199 61.311 77.2715 67.2581 67.5845 67.6387C67.0549 67.6595 66.5233 67.6685 65.9902 67.6662C54.94 67.6203 42.1533 62.7569 32.1208 55.5803C21.6993 48.1253 13.4172 37.544 14.0322 26.3382C14.1904 23.4541 15.665 21.1464 17.8258 19.4305C19.9327 17.7574 22.7418 16.5926 25.8747 15.8029C30.2263 14.7059 35.5317 14.2521 41.2383 14.3628C44.5098 14.2914 47.9722 14.4568 51.4953 14.8367C59.5116 15.7012 68.0324 17.6973 75.6321 20.7198C82.5399 23.4671 88.5506 27.4167 93 32C93 32 100.5 32 104 32C107 32 107 36 104 36C100.485 36 95 36 95 36Z"/>
      <path id="glasses-2" d="M33.8518 59.2747C29.144 53.7522 25.6805 46.8598 25.9045 37.5436C26.5166 12.0826 62.7183 9.6367 79.1479 20.9798C84.7894 24.8746 88.4372 30.8071 88.5112 37.703C88.7933 63.9201 52.0905 77.4735 33.8518 59.2747ZM93 38.9999C93 38.9999 99.7042 38.9999 104 38.9999C108 39 108 34 104 34C100.5 34 93 34 93 34C87.9633 -2.39844 18.9193 -0.294821 18.0125 37.4235C17.7634 47.7865 21.2335 57.1995 27.6436 63.9312C48.4013 85.7304 91.8699 69.6047 93 38.9999Z"/>
      <path id="glasses-3" d="M32.0051 44.5279C31.725 60.3502 42.7998 74.0943 58.0959 74.7207C96.3414 76.2868 96.8594 15.3864 60.4878 14.5214C43.4077 14.1152 32.2923 28.3024 32.0051 44.5279ZM93 48C90.3574 66.2343 77.1572 80.5054 57.8913 79.7165C39.3805 78.9585 26.6871 62.4471 27.0059 44.4394C27.3417 25.4701 40.7803 9.05134 60.6067 9.52285C79.0279 9.96095 91.1647 24.5053 93 42C93 42 99 42 104 42C109 42 109 48 104 48C99 48 93 48 93 48Z"/>
      <path id="glasses-4" d="M30.5757 13.2881C24.6722 14.8866 20.6314 17.1727 18.8154 19.4395C18.3867 19.9745 18.1237 20.5968 17.9495 21.1133C17.7641 21.663 17.6193 22.2778 17.5035 22.9195C17.2716 24.2043 17.1246 25.775 17.0526 27.5109C16.9083 30.9924 17.0579 35.3482 17.536 39.8351C18.0139 44.3193 18.8279 49.0104 20.0408 53.1438C21.2317 57.2022 22.8944 61.0352 25.25 63.5151C28.1901 66.6105 32.9343 68.509 38.0342 69.6505C43.1599 70.7978 49.0334 71.2642 54.7341 71.2151C55.3041 71.2203 55.873 71.2196 56.4398 71.2131C61.8434 71.1506 67.1575 70.5587 71.5408 69.557C73.7314 69.0564 75.739 68.4423 77.4303 67.7149C79.0809 67.0051 80.6213 66.1072 81.6926 64.9434C82.5031 64.0632 83.202 62.859 83.8087 61.6036C84.4333 60.3112 85.0386 58.7969 85.6154 57.1772C86.7696 53.9363 87.8621 50.1242 88.8013 46.5129C89.9648 42.0396 91.0447 37.5238 92 33C92 33 98 33 104 33C110 33 110 27 104 27C98 27 90 27 90 27C88.8829 25.347 86.9975 23.6632 84.7135 21.6373C79.28 16.8178 70.2577 12.001 55.7716 11.2222C44.9421 10.6399 36.6108 11.6538 30.5757 13.2881ZM78.0141 61.5569C77.657 61.9448 76.8397 62.5037 75.3694 63.1035C73.9517 63.6819 72.1311 64.2171 69.9917 64.6752C65.7166 65.5907 60.3767 66.1542 54.884 66.2134C54.8345 66.2139 54.7851 66.2144 54.7356 66.2148C50.1722 66.1699 45.6339 65.7147 41.6888 64.7784C37.2034 63.714 33.8042 62.1024 31.8752 60.0716C30.3098 58.4235 28.7958 55.5126 27.4965 51.7567C26.2195 48.065 25.2344 43.8093 24.5933 39.7083C23.9511 35.6001 23.6686 31.7399 23.7571 28.843C23.8017 27.3824 23.9382 26.2683 24.1257 25.5242C24.183 25.297 24.2372 25.1361 24.2798 25.0289C27.3401 23.4364 31.9349 22.1032 37.4519 21.2898C43.1224 20.4538 49.601 20.1913 56.0729 20.6908C74.2168 22.0909 89.8853 25.3262 83.9623 45.2543C83.0392 48.8036 81.9889 52.4567 80.9052 55.4998C80.3631 57.0221 79.8256 58.3546 79.3069 59.4279C78.7702 60.5384 78.3252 61.219 78.0141 61.5569Z"/>
      <path id="glasses-5" d="M74.9564 59.6286C85.345 56.2707 90.7124 42.4772 94.2926 33.0312C94.2926 33.0312 100.517 33.0312 104 33.0312C111.765 33.0312 111.515 25.1055 104 25.1055H92.0436C75.9981 10.2181 40.1663 12.0898 9.26331 20.261C7.9803 20.3833 7 21.461 7 22.7498V30.7498C7 31.7605 7.60855 32.6718 8.54211 33.059L15.7204 36.0367C17.555 45.2437 21.2505 56.6473 31.2611 59.6383C43.4711 63.538 62.4132 63.683 74.9564 59.6286ZM21.163 30.2052C21.7413 27.084 23.5013 25.4571 25.1498 24.5463C36.2713 18.4016 77.592 16.2127 86.5477 29.1443C92.1624 37.2516 81.0804 52.3945 73.4186 54.871C62.8409 58.2901 43.3466 58.2901 32.7689 54.871C24.1002 52.069 19.5915 38.6878 21.163 30.2052Z"/>
      <path id="glasses-6" d="M43.5297 67.6369C25.8028 66.3562 23.686 46.8159 24.0368 32.5366C24.2046 25.709 27.5411 20.0297 33.8359 17.1945C40.4231 14.2278 47.9089 14.3242 54.9252 15.2999C63.7204 16.5231 72.4764 19.3463 78.995 22.8141C82.3739 24.6117 89.0234 28.1474 89.1349 32.7313C89.6249 52.8857 60.4361 66.8787 43.5297 67.6369ZM29.2867 12.62C21.0954 16.1581 16.2572 23.5073 16.0384 32.4138C15.5777 51.1642 20.7639 74.4518 43.458 73.6437C63.5424 74.9385 92.6558 56.0975 94 34C94 34 100 34 104 34C108 34 108 29 104 29C100 29 93 29 93 29C91.0013 23.9939 86.3618 20.9242 81.851 18.421C75.0423 14.6427 66.0826 11.6487 57.1284 10.3497C48.0418 9.03144 37.8265 8.93142 29.2867 12.62Z"/>

    </defs>
  </svg>
);

export default AllSVGs;

